import {Data, DataValue, NotCondition, RefPath, Valx} from '../types';
import {evaluateCondition} from './evaluateCondition';

export const evaluateNotCondition = (
  condition: NotCondition<Valx>,
  /**
   * Path from global scope to evaluation context
   */
  contextPath: RefPath,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  localValue: DataValue | undefined,
): boolean => {
  return !evaluateCondition(
    condition.arg,
    contextPath,
    localScope,
    globalScope,
    relatedScope,
    localValue,
  );
};
