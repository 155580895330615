import {assert} from 'assert-ts';
import {CodeListMap} from 'api/types';
import {Data, Part, Valx} from 'schemaDefinition/types';
import {ValidationResult} from './types';
import {getValue} from './getValue';
import {validResult} from './validResult';
import {validateCodeList} from './validateCodeList';
import {validateCodeListOrTextValue} from './validateCodeListOrTextValue';
import {validateDate} from './validateDate';
import {validateInt} from './validateInt';
import {validateLinkedAgent} from './validateLinkedAgent';
import {validateLinkedLiterary} from './validateLinkedLiterary';
import {validateNameVariant} from './validateNameVariant';
import {validateText} from './validateText';
import {validateThesaurus} from './validateThesaurus';
import {validateYear} from './validateYear';
import {validateYearOrDate} from './validateYearOrDate';

/**
 * validates data[part.name] according to "value" part
 * @param part to validate against
 * @param data to be validated, aka "local scope"
 * @param dataPath path from root object (global scope) to data
 * @param globalScope root data object to allow for evaluation of conditions
 * @param aggregateResult evaluation so far
 * @returns aggregateResult expanded with any validation results
 */
export const validateValuePart = (
  part: Part<Valx>,
  data: Data,
  dataPath: string,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  codelistMap: CodeListMap | undefined,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  const {value, valuePath} = getValue(data, part, dataPath);

  const result = aggregateResult;

  switch (part.type) {
    case 'text':
    case 'textarea': {
      return validateText(
        part,
        value,
        valuePath,
        data,
        globalScope,
        relatedScope,
        result,
      );
    }
    case 'html':
      return aggregateResult;
    case 'int': {
      return validateInt(
        part,
        value,
        valuePath,
        data,
        globalScope,
        relatedScope,
        result,
      );
    }
    case 'bool': {
      return result;
    }
    case 'year': {
      return validateYear(
        part,
        value,
        valuePath,
        data,
        globalScope,
        relatedScope,
        result,
      );
    }
    case 'date': {
      return validateDate(
        part,
        value,
        valuePath,
        data,
        globalScope,
        relatedScope,
        result,
      );
    }
    case 'yearOrDate': {
      return validateYearOrDate(
        part,
        value,
        valuePath,
        data,
        globalScope,
        relatedScope,
        result,
      );
    }
    case 'codelist': {
      return validateCodeList(
        part,
        value,
        valuePath,
        data,
        globalScope,
        relatedScope,
        codelistMap,
        result,
      );
    }
    case 'codelist|text': {
      return validateCodeListOrTextValue(
        part,
        value,
        valuePath,
        data,
        globalScope,
        relatedScope,
        result,
      );
    }
    case 'linkedAgent': {
      return validateLinkedAgent(
        part,
        value,
        valuePath,
        data,
        globalScope,
        relatedScope,
        codelistMap,
        result,
      );
    }
    case 'linkedLiterary': {
      return validateLinkedLiterary(
        part,
        value,
        valuePath,
        data,
        globalScope,
        relatedScope,
        codelistMap,
        result,
      );
    }
    case 'nameVariant': {
      return validateNameVariant(
        part,
        value,
        valuePath,
        data,
        globalScope,
        relatedScope,
        result,
      );
    }
    case 'thesaurus': {
      return validateThesaurus(
        part,
        value,
        valuePath,
        data,
        globalScope,
        relatedScope,
        result,
      );
    }

    default: {
      assert(false, 'Validate: Not implemented yet: ' + part.type);
      return result;
    }
  }
};
