import assert from 'assert-ts';
import {
  Data,
  DataValue,
  FieldRequired,
  FieldRequiredSimple,
  RefPath,
  Valx,
} from '../types';
import {evaluateCondition} from './evaluateCondition';

export const evaluateFieldRequired = (
  field: FieldRequired<Valx> | undefined,
  contextPath: RefPath,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  localValue: DataValue | undefined,
): FieldRequiredSimple | undefined => {
  if (typeof field === 'boolean' || field === 'should' || field === undefined) {
    return field;
  }

  if (field.required) {
    return evaluateCondition(
      field.required,
      contextPath,
      localScope,
      globalScope,
      relatedScope,
      localValue,
    );
  }

  if (field.should) {
    return evaluateCondition(
      field.should,
      contextPath,
      localScope,
      globalScope,
      relatedScope,
      localValue,
    )
      ? 'should'
      : false;
  }

  assert(
    !field.should && !field.required,
    'FieldRequired should have should or required',
  );
};
