import React from 'react';
import {
  useSchemaGlobalScopeContextV1,
  useSchemaLocalScopeContextV1,
} from 'schema/contexts';
import {PreviewPart, PreviewPartProps} from './PreviewPart';

/**
 * Render part, wrapped with global and local scope, as needed by part
 */
export const PreviewPartWrapper: React.FC<PreviewPartProps> = props => {
  const {values: globalScope} = useSchemaGlobalScopeContextV1();
  const {values: localScope} = useSchemaLocalScopeContextV1();

  return (
    <PreviewPart globalScope={globalScope} scope={localScope} {...props} />
  );
};
