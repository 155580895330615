import {valueAsNumber} from 'services/utils';
import {Data, DataValue, LengthCondition, RefPath, Valx} from '../types';
import {evaluateValx} from './evaluateValx';
import {evaluateValxWithDefault} from './evaluateValxWithDefault';

export const evaluateLengthCondition = (
  condition: LengthCondition<Valx>,
  /**
   * Path from global scope to evaluation context
   */
  contextPath: RefPath,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  localValue: DataValue | undefined,
): boolean => {
  const minLength = condition.minLength
    ? valueAsNumber(
        evaluateValx(
          condition.minLength,
          contextPath,
          localScope,
          globalScope,
          relatedScope,
        ),
      )
    : undefined;
  const maxLength = condition.maxLength
    ? valueAsNumber(
        evaluateValx(
          condition.maxLength,
          contextPath,
          localScope,
          globalScope,
          relatedScope,
        ),
      )
    : undefined;
  const arg1 = evaluateValxWithDefault(
    condition.arg1,
    contextPath,
    localScope,
    globalScope,
    relatedScope,
    localValue,
  );

  return (
    (typeof arg1 === 'string' || Array.isArray(arg1)) &&
    (minLength === undefined || arg1.length >= minLength) &&
    (maxLength === undefined || arg1.length <= maxLength)
  );
};
