import {CodeList, CodeListMap} from 'api/types';
import {Metadata} from '../types';
import {getExpressionManifestationsLocalCodelist} from './getExpressionManifestationsLocalCodelist';

export const getExpressionLocalCodelistMap = (
  expressionId: string,
  state: {metadataEdit: {metadata?: Pick<Metadata, 'manifestations'>}},
  productFormOverrideCodelist: CodeList,
  productFormCodelist: CodeList,
): CodeListMap => {
  const expressionManifestationCodelist =
    getExpressionManifestationsLocalCodelist(
      state,
      expressionId,
      productFormOverrideCodelist,
      productFormCodelist,
    );
  return {
    expression_manifestation: expressionManifestationCodelist,
  };
};
