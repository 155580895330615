import {useMemo} from 'react';
import {ChangeRequestAction, PartRef} from 'schemaDefinition/types';
import {useLocalization} from 'localization';
import {useLabelAndPlaceholder} from './useLabelAndPlaceholder';
import {usePartConfiguration} from './usePartConfiguration';

/**
 * Returns a tooltip for the part change action.
 * @param part The part to get the tooltip for.
 * @returns The tooltip.
 */
export const usePartChangeActionTooltip = (
  action: ChangeRequestAction,
  part?: PartRef,
): string => {
  const localization = useLocalization();
  const configurationPart = usePartConfiguration(part);
  const {label} = useLabelAndPlaceholder(part ?? {}, false);

  return useMemo(() => {
    const configTooltip = configurationPart?.compare?.actionTooltip?.[action];
    if (configTooltip) {
      return configTooltip;
    }

    const replace = localization.t(`page.metadata.cr.${action}`);
    if (!part) {
      return replace;
    }

    return label ? `${replace} ${label.toLowerCase()}` : replace;
  }, [configurationPart, label, localization, part, action]);
};
