import {assert} from 'assert-ts';
import {ManifestationV4} from '../types';
import {Schemas} from '../dto.generated';
import {mapAgentRoleDto, mapToAgentRoleListDto} from './mapAgentRoleDto';
import {mapImprintDto, mapToImprintDto} from './mapImprintDto';
import {
  mapPhysicalPropertiesDto,
  mapToPhysicalPropertiesDto,
} from './mapPhysicalPropertiesDto';
import {
  mapManifestationRelationsDto,
  mapToManifestationRelationsDto,
} from './mapRelationsDto';
import {mapRuntime, mapToRuntime} from './mapRuntime';
import {mapTitleInfoDto, mapToTitleInfoDto} from './mapTitleInfoDto';

export const mapManifestationBaseDto = (
  dto: Schemas.ManifestationBaseDto,
): ManifestationV4 => {
  const {
    id,
    created,
    modified,
    expressionId,
    status,
    agents,
    imprints,
    unverifiedImprints,
    seriesRelations,
    physicalProperties,
    marketingData,
    titleInfo,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    mainTitle,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    subTitles,
    generalNote,
    editionNote,
    editionType,
    attachment,
    ddsData,
    runtimeSeconds,
    ...rest
  } = dto;

  const links = mapManifestationRelationsDto({seriesRelations});
  const physicalPropertyFields = mapPhysicalPropertiesDto(physicalProperties);

  return {
    id: assert(id, 'mapManifestationBaseDto: id expected', dto),
    created: assert(created, 'mapManifestationBaseDto: created expected', dto),
    modified: assert(
      modified,
      'mapManifestationBaseDto: modified expected',
      dto,
    ),
    expressionId: assert(
      expressionId,
      'mapManifestationBaseDto: expressionId expected',
      dto,
    ),
    products: marketingData ? marketingData.products : undefined,
    ddsStatus: ddsData?.ebooks,
    ...mapTitleInfoDto(titleInfo),
    ...rest,
    status: assert(status, 'mapManifestationBaseDto: status expected', dto),
    agents: (agents ?? []).map(mapAgentRoleDto),
    imprints: [
      ...(Array.isArray(imprints) && imprints.length > 0
        ? imprints.map(mapImprintDto)
        : []),
      ...(Array.isArray(unverifiedImprints) && unverifiedImprints.length > 0
        ? unverifiedImprints.map(mapImprintDto)
        : []),
    ],
    links,
    ...physicalPropertyFields,
    notes: {
      generalNote,
      editionNote,
      editionType,
      attachment,
    },
    runtimeSeconds: mapToRuntime(runtimeSeconds),
  };
};

export const mapToManifestationBaseDto = (
  data: ManifestationV4,
): Schemas.ManifestationBaseDto => {
  const {
    agents,
    imprints,
    products,
    productForm,
    productFormDetail,
    colour,
    epages,
    scale,
    mainTitles,
    otherTitles,
    notes,
    pageCount,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    links,
    runtimeSeconds,
    ...rest
  } = data;
  const relations = mapToManifestationRelationsDto(links);

  // Ignore empty imprints (added item without connecting anything).
  // Ignore unverified imprints.
  const verifiedImprints = imprints
    ? imprints.filter(i => i.link?.linkStatus === 'verified')
    : [];

  return {
    ...rest,
    agents: mapToAgentRoleListDto(agents),
    marketingData: products ? {products} : undefined,
    imprints:
      verifiedImprints.length > 0
        ? verifiedImprints.map(mapToImprintDto)
        : undefined,
    ...relations,
    productForm: (productForm ??
      null) as Schemas.ManifestationBaseDto['productForm'],
    productFormDetail: (productFormDetail ??
      null) as Schemas.ManifestationBaseDto['productFormDetail'],
    physicalProperties: mapToPhysicalPropertiesDto(colour, epages, scale),
    titleInfo: mapToTitleInfoDto(mainTitles, otherTitles),
    generalNote: notes?.generalNote ?? undefined,
    editionNote: notes?.editionNote ?? undefined,
    editionType: notes?.editionType ?? undefined,
    attachment: notes?.attachment ?? undefined,
    pageCount: pageCount && pageCount.length > 0 ? pageCount : undefined,
    runtimeSeconds: mapRuntime(runtimeSeconds),
  };
};
