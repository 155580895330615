import {isNullish} from 'services/utils';
import {Data, DataValue, PathKeys} from '../types';
import {evaluateByPath} from './evaluateByPath';
import {evaluateByPathKeys} from './evaluateByPathKeys';

export const evaluatePropValue = (
  contextPath: string,
  pathKeys: PathKeys,
  scope: Data,
): DataValue => {
  const parentValue = evaluateByPath(contextPath, scope);
  if (isNullish(parentValue) || typeof parentValue !== 'object') {
    return null;
  }

  const propValue = evaluateByPathKeys(pathKeys, parentValue as Data, false);
  return propValue;
};
