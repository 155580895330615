import {assert} from 'assert-ts';
import {CodeListMap} from 'api/types';
import {
  Data,
  DataSimpleValue,
  DataValue,
  Part,
  Valx,
} from 'schemaDefinition/types';
import {ValidateSingleTypeValue, ValidationResult} from './types';
import {isMultiple} from '../isMultiple';
import {validResult} from './validResult';
import {validateConditions} from './validateConditions';
import {validateMultipleValue} from './validateMultipleValue';

/**
 * validates data[part.name] according to "value" part
 * @param part to validate against
 * @param validateSingleTypeValue function to validate single value for part type
 * @param value value to be validated, single value according to part type or array of values
 * @param valuePath path from root object (global scope) to value
 * @param localScope enclosing object of value to be validated
 * @param globalScope root data object to allow for evaluation of conditions
 * @param relatedScope related data object to allow for evaluation of conditions, e.g. {work} for conditions on expression
 * @param aggregateResult evaluation so far
 * @returns aggregateResult expanded with any validation results
 */
export const validateValueType = <TPart extends Part<Valx>>(
  part: TPart,
  validateSingleTypeValue: ValidateSingleTypeValue<TPart>,
  value: DataValue | undefined,
  valuePath: string,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  codelistMap: CodeListMap | undefined,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  let result = aggregateResult;
  if (isMultiple(part.cardinality)) {
    result = validateMultipleValue(
      part,
      value,
      valuePath,
      localScope,
      globalScope,
      relatedScope,
      result,
    );

    // No further validation if the value is not an array
    if (value === undefined || value === null) {
      return result;
    }
  }

  if (isMultiple(part.cardinality)) {
    if (part.listValidation) {
      result = validateConditions(
        value,
        part.listValidation,
        valuePath,
        part,
        localScope,
        globalScope,
        relatedScope,
        result,
      );
    }

    const items = value as Data[] | DataSimpleValue[];
    items.forEach((_, idx) => {
      const {item, itemPath} = getItemValue(items, idx, valuePath);
      result = validateSingleTypeValue(
        part,
        item,
        itemPath,
        localScope,
        globalScope,
        relatedScope,
        codelistMap,
        result,
      );
    });
    return result;
  } else {
    assert.soft(
      !part.listValidation,
      'validateValueType: listValidation is only valid for cardinality > 1',
      part,
    );
    return validateSingleTypeValue(
      part,
      value,
      valuePath,
      localScope,
      globalScope,
      relatedScope,
      codelistMap,
      result,
    );
  }
};

const getItemValue = (
  value: Data[] | DataSimpleValue[],
  idx: number,
  valuePath: string,
): {item: Data | DataSimpleValue | undefined; itemPath: string} => {
  const itemPath = `${valuePath}[${idx}]`;
  const item = value[idx];
  return {item, itemPath};
};
