import {CodeListMap} from 'api/types';
import {Data, DataValue, PartInt, Valx} from 'schemaDefinition/types';
import {ValidationResult} from './types';
import {evaluateFieldRequired} from '../../../../schemaDefinition';
import {fail} from './fail';
import {validResult} from './validResult';
import {validateConditions} from './validateConditions';
import {validateValueType} from './validateValueType';
import {warning} from './warning';

export const validateInt = (
  part: PartInt<Valx>,
  value: DataValue | undefined,
  valuePath: string,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  return validateValueType(
    part,
    validateSingleIntValue,
    value,
    valuePath,
    localScope,
    globalScope,
    relatedScope,
    undefined,
    aggregateResult,
  );
};

export const validateSingleIntValue = (
  part: PartInt<Valx>,
  value: DataValue | undefined,
  valuePath: string,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  _codelistMap: CodeListMap | undefined,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  if (value === undefined || value === null || value === '') {
    const required = evaluateFieldRequired(
      part.required,
      valuePath,
      localScope,
      globalScope,
      relatedScope,
      value,
    );
    return required
      ? (required === true ? fail : warning)(
          {
            value,
            valuePath,
            part,
            localScope,
            validation: 'required',
            messageKey: 'required',
          },
          aggregateResult,
        )
      : aggregateResult;
  }

  if (typeof value !== 'number' || isNaN(value)) {
    return fail(
      {
        value,
        valuePath,
        part,
        localScope,
        validation: 'invalid type',
        messageKey: 'invalid_number',
      },
      aggregateResult,
    );
  }

  if (part.validation) {
    return validateConditions(
      value,
      part.validation,
      valuePath,
      part,
      localScope,
      globalScope,
      relatedScope,
      aggregateResult,
    );
  }

  return aggregateResult;
};
