import {
  Data,
  DataValue,
  PartCodeListOrText,
  Valx,
} from 'schemaDefinition/types';
import {ValidationResult} from './types';
import {validResult} from './validResult';
import {validateSingleTextValue} from './validateText';
import {validateValueType} from './validateValueType';

export const validateCodeListOrTextValue = (
  part: PartCodeListOrText<Valx>,
  value: DataValue | undefined,
  valuePath: string,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  return validateValueType(
    part,
    validateSingleTextValue,
    value,
    valuePath,
    localScope,
    globalScope,
    relatedScope,
    undefined,
    aggregateResult,
  );
};
