import {assert} from 'assert-ts';
import {Data, DataValue, RefPath, Valx} from '../types';
import {evaluateByNameRef} from './evaluateByNameRef';
import {evaluateByPathKeys} from './evaluateByPathKeys';
import {evaluatePropValue} from './evaluatePropValue';

/**
 * If any values cannot be converted to argType, then the condition is undefined.
 */
export const evaluateValx = (
  valx: Valx | undefined,
  /**
   * Path from global scope to evaluation context
   */
  contextPath: RefPath,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
): DataValue => {
  if (valx === undefined) {
    return null;
  }

  const {
    value,
    propPathKeys,
    localPathKeys,
    globalPathKeys,
    globalByName,
    relatedPathKeys,
  } = valx;
  // Constant value
  if (value !== undefined) {
    return value;
  }

  // Reference value
  // - prop keys
  if (propPathKeys) {
    const assertProps = {
      valx,
      contextPath,
      localScope,
    };
    assert(
      localScope !== undefined,
      'evaluateValx: localScope must be set when propPathKeys',
      assertProps,
    );
    return evaluatePropValue(contextPath, propPathKeys, localScope);
  }

  // - local keys
  if (localPathKeys) {
    const assertProps = {
      valx,
      contextPath,
      localScope,
    };
    assert(
      localScope !== undefined,
      'evaluateValx: localScope must be set when localPathKeys',
      assertProps,
    );
    return evaluateByPathKeys(localPathKeys, localScope, assertProps);
  }

  // - global keys
  if (globalPathKeys) {
    const assertProps = {
      valx,
      contextPath,
      globalScope,
    };
    assert(
      globalScope !== undefined,
      'evaluateValx: globalScope must be set when globalPathKeys',
      assertProps,
    );
    return evaluateByPathKeys(globalPathKeys, globalScope, assertProps);
  }

  // - global by name
  if (globalByName) {
    const globalS = assert(
      globalScope,
      'evaluateValx(global by name): global scope expected',
    );
    return evaluateByNameRef(globalByName, globalS, contextPath);
  }

  // - related keys
  if (relatedPathKeys) {
    const assertProps = {
      valx,
      contextPath,
      relatedScope,
    };
    assert(
      relatedScope !== undefined,
      'evaluateValx: relatedScope must be set when relatedPathKeys',
      assertProps,
    );
    return evaluateByPathKeys(relatedPathKeys, relatedScope, assertProps);
  }

  assert(false, 'evaluateValx: invalid valx - all undefined', {
    valx,
    contextPath,
  });
};
