import {Data, DataValue, OrCondition, RefPath, Valx} from '../types';
import {evaluateCondition} from './evaluateCondition';

export const evaluateOrCondition = (
  condition: OrCondition<Valx>,
  /**
   * Path from global scope to evaluation context
   */
  contextPath: RefPath,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  localValue: DataValue | undefined,
): boolean => {
  return condition.arg.some(cond =>
    evaluateCondition(
      cond,
      contextPath,
      localScope,
      globalScope,
      relatedScope,
      localValue,
    ),
  );
};
