import React, {Fragment, useMemo} from 'react';
import {assert} from 'assert-ts';
import {LinkAction} from 'types';
import {IconName} from 'components/icons/types';
import {Linking} from 'schema/types';
import {VerifiedLinkedCatalogPostLink} from 'schemaDefinition/types';
import {useLocalization} from 'localization';
import {WorkWithExpressions} from 'api';
import {assertLinkTargetCatalogPostType} from 'services/utils';
import {ActionButton, Spacer} from 'components';
import {getLinkButtonIconAndTitle} from 'schema/preview/hooks';
import {LinkedType} from '../types';

type Props = {
  title: string;
  entityValue?: WorkWithExpressions;
  linked: LinkedType;
  linkingConfiguration: Linking;
};

export const LinkCatalogPostButtons: React.FC<Props> = ({
  title,
  entityValue,
  linked,
  linkingConfiguration,
}) => {
  const localization = useLocalization();

  const actions = useMemo((): {
    linkAction: LinkAction;
    icon: IconName;
    title: string;
    handleLinkAction: () => Promise<void>;
  }[] => {
    const {entityId, entityType, link, unlink, move, copy} =
      linkingConfiguration;

    const availableActions: LinkAction[] =
      linked === 'none'
        ? link
          ? ['link']
          : move
            ? ['copy', 'move']
            : ['copy']
        : unlink
          ? ['unlink']
          : [];

    const actionMap: {[key in LinkAction]: typeof link} = {
      link: link,
      unlink: unlink,
      changeLink: link,
      move: move,
      copy: copy,
    };

    return availableActions.map(action => {
      const handleClick =
        action === 'unlink'
          ? assert(
              unlink,
              'LinkCatalogPostButton: unlink expected',
              linkingConfiguration,
            )
          : () => {
              const linkValue: VerifiedLinkedCatalogPostLink = {
                linkStatus: 'verified',
                entityId: assert(
                  entityId,
                  'LinkCatalogPostButton: entityId expected',
                ),
                type: assertLinkTargetCatalogPostType(entityType),
                name: title,
                entityValue:
                  action === 'move' || action === 'copy'
                    ? entityValue
                    : undefined,
              };
              assert(
                actionMap[action],
                'LinkCatalogPostButton: action expected',
                {action},
              )(linkValue);
            };
      return {
        linkAction: action,
        ...getLinkButtonIconAndTitle(action, entityType, localization),
        handleLinkAction: () => Promise.resolve(handleClick()),
      };
    });
  }, [entityValue, linked, linkingConfiguration, localization, title]);

  return actions.length > 0 ? (
    <>
      {actions.map(({title, icon, handleLinkAction}, idx) => (
        <Fragment key={title}>
          {idx > 0 ? <Spacer width={1} /> : null}
          <ActionButton title={title} icon={icon} onClick={handleLinkAction} />
        </Fragment>
      ))}
    </>
  ) : null;
};
