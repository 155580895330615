import {assert} from 'assert-ts';
import {valueAsNumber} from 'services/utils';
import {Data, DataValue, ExpressionCondition, RefPath, Valx} from '../types';
import {evaluateValx} from './evaluateValx';
import {evaluateValxWithDefault} from './evaluateValxWithDefault';

/**
 * If any values cannot be converted to argType, then the condition is undefined.
 */
export const evaluateExpressionCondition = (
  condition: ExpressionCondition<Valx>,
  /**
   * Path from global scope to evaluation context
   */
  contextPath: RefPath,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  localValue: DataValue | undefined,
): boolean =>
  //  | undefined
  {
    const arg1 = evaluateValxWithDefault(
      condition.arg1,
      contextPath,
      localScope,
      globalScope,
      relatedScope,
      localValue,
    );
    const arg2 = evaluateValx(
      condition.arg2,
      contextPath,
      localScope,
      globalScope,
      relatedScope,
    );

    const {op} = condition;

    // Allows eq operator for any type
    if (op === 'eq') {
      if (arg1 === arg2) return true;
      const num1 = valueAsNumber(arg1);
      const num2 = valueAsNumber(arg2);
      if (num1 !== undefined && num1 === num2) return true;

      return JSON.stringify(arg1) === JSON.stringify(arg2);
    }

    const numArg1 = valueAsNumber(arg1);
    const numArg2 = valueAsNumber(arg2);

    if (numArg1 === undefined || numArg2 === undefined) return false;

    switch (op) {
      case 'lt':
        return numArg1 < numArg2;
      case 'le':
        return numArg1 <= numArg2;
      case 'ge':
        return numArg1 >= numArg2;
      case 'gt':
        return numArg1 > numArg2;
    }

    assert.soft(false, 'evaluateExpressionCondition: unknown operator', {op});
    return false;
  };
