import {Thesaurus, ThesaurusNodeType, ThesaurusValue} from 'api/types';
import {assertThesaurusNodeWithAncestors} from 'services/thesaurus/functions';
import {SelectAction} from '../types';

export const getSelectAction = (
  focusNodeId: string,
  thesaurusEditValue: ThesaurusValue | undefined,
  thesaurus: Thesaurus,
  /** If set, only nodes of given types will have action */
  selectableNodeTypes: ThesaurusNodeType[] | undefined,
): SelectAction | undefined => {
  const focusNode = assertThesaurusNodeWithAncestors(focusNodeId, thesaurus);

  if (
    selectableNodeTypes &&
    !(focusNode.node.type && selectableNodeTypes.includes(focusNode.node.type))
  ) {
    return undefined;
  }

  if (!thesaurusEditValue || thesaurusEditValue.length === 0) {
    return {type: 'select'};
  }

  if (thesaurusEditValue && thesaurusEditValue.includes(focusNodeId)) {
    return {type: 'remove', relatedNodeIds: [focusNodeId]};
  }

  const focusPath = [
    // Skip the root node
    ...focusNode.ancestors.map(node => node.id).slice(1),
    focusNodeId,
  ];
  const editValueNodes = thesaurusEditValue.map(id => {
    return assertThesaurusNodeWithAncestors(id, thesaurus);
  });
  const editValuePaths = editValueNodes.map(value => {
    // Skip the root node
    return [...value.ancestors.map(node => node.id).slice(1), value.node.id];
  });

  const relatedIds = editValuePaths
    .filter(path => {
      const isSuper = path.includes(focusNodeId);
      const isSub = focusPath.includes(path[path.length - 1]);
      return isSuper || isSub;
    })
    .map(path => path[path.length - 1]);

  if (relatedIds.length === 0) {
    return {type: 'select'};
  } else {
    return {type: 'swap', relatedNodeIds: relatedIds};
  }
};
