import React, {useMemo} from 'react';
import {CodeListFieldProps} from '../types';
import {Layout} from '../../../layout';
import {FieldLabel} from '../../FieldLabel';
import {NullFieldPlaceholder} from '../../NullFieldPlaceholder';
import '../CodeListField.scss';
import {getSelectedOptions} from '../functions';
import {SortableCodeListFieldRaw} from './SortableCodeListFieldRaw';

export const SortableCodeListField: React.FC<CodeListFieldProps> = ({
  name,
  id,
  value,
  cardinality = 'single',
  showCode,
  readonly = false,
  showWhenReadonlyAndEmpty = false,
  error,
  required = false,
  label,
  placeholder,
  options,
  fullWidth,
  width,
  maxWidth,
  flex,
  alignItems,
  renderOptionContent,
  onChange,
  onBlur,
  'data-cy': dataCy,
}) => {
  const selectedCodes = useMemo(
    () => getSelectedOptions(value, cardinality, options, false),
    [value, cardinality, options],
  );

  const showNull =
    readonly &&
    !showWhenReadonlyAndEmpty &&
    (value === undefined ||
      value === null ||
      (Array.isArray(value) && value.length === 0));

  return cardinality === 'single' ? (
    <Layout
      width={width}
      maxWidth={maxWidth}
      flex={flex}
      alignItems={alignItems}>
      {label && (
        <FieldLabel
          label={label}
          required={required}
          error={error}
          htmlFor={id}
        />
      )}
      {showNull ? (
        <NullFieldPlaceholder />
      ) : (
        <SortableCodeListFieldRaw
          name={name}
          id={id}
          value={value}
          cardinality={cardinality}
          showCode={showCode}
          readonly={readonly}
          showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
          error={error}
          required={required}
          placeholder={placeholder}
          options={options}
          fullWidth={fullWidth}
          renderOptionContent={renderOptionContent}
          selectedCodes={selectedCodes}
          onChange={onChange}
          onBlur={onBlur}
          data-cy={dataCy}
        />
      )}
    </Layout>
  ) : (
    // Trick to keep height stable on focus/blur: Since blurred selected items
    // are a bit larger than focused onces, keep hidden blurred duplicate  of
    // selected items to determine size
    <Layout
      width={width}
      maxWidth={maxWidth}
      flex={flex}
      alignItems={alignItems}>
      {label && (
        <FieldLabel
          label={label}
          required={required}
          error={error}
          htmlFor={id}
        />
      )}
      {showNull ? (
        <NullFieldPlaceholder />
      ) : (
        <Layout horizontal flex={1} sx={{position: 'relative'}}>
          <Layout
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
            }}>
            <SortableCodeListFieldRaw
              name={name}
              id={id}
              value={value}
              cardinality={cardinality}
              placeholder={placeholder}
              showCode={showCode}
              readonly={readonly}
              showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
              error={error}
              options={options}
              fullWidth={fullWidth}
              renderOptionContent={renderOptionContent}
              selectedCodes={selectedCodes}
              onChange={onChange}
              onBlur={onBlur}
              data-cy={dataCy}
            />
          </Layout>
          {/* Hidden duplicate which never gets focus */}
          <Layout
            className="multiple-sizer"
            flex={1}
            sx={{visibility: 'hidden'}}>
            <SortableCodeListFieldRaw
              name={name}
              id={`${id}-sizer`}
              value={value}
              cardinality={cardinality}
              showCode={showCode}
              placeholder={placeholder}
              readonly={readonly}
              showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
              error={error}
              options={options}
              fullWidth={fullWidth}
              renderOptionContent={renderOptionContent}
              selectedCodes={selectedCodes}
            />
          </Layout>
        </Layout>
      )}
    </Layout>
  );
};
