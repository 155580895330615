import {Data, DataValue, Part, Valx} from 'schemaDefinition/types';

export const getValue = (
  data: Data,
  part: Part<Valx>,
  valuePath: string,
): {value: DataValue | undefined; valuePath: string} => {
  const path = valuePath ? valuePath + '.' + part.name : part.name ?? '';
  const value = part.name ? data?.[part.name] : undefined;
  return {value, valuePath: path};
};
