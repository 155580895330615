import {Part, Valx} from '../types';
import {ValueVisitor} from './types';
import {visitPartValues} from './visitSchemaValues';

export const getScopeDependencies = (
  part: Part<Valx> | undefined,
): {local: boolean; global: boolean; related: boolean} => {
  if (!part) {
    return {
      local: false,
      global: false,
      related: false,
    };
  }
  let local = false;
  let global = false;
  let related = false;

  const localPartVisitor: ValueVisitor<Valx, Valx> = ({
    propPathKeys,
    localPathKeys,
  }: Valx) => {
    if (localPathKeys || propPathKeys) local = true;
  };

  const relatedPartVisitor: ValueVisitor<Valx, Valx> = ({
    globalByName,
    globalPathKeys,
    relatedPathKeys,
  }: Valx) => {
    if (globalPathKeys || globalByName) global = true;
    if (relatedPathKeys) related = true;
  };

  // TODO: consider explicit property on part schema if recursive is required
  /*
  const recursive =
    part.type === 'schema' &&
    // Recurse scope dependencies to be able to do aggregated validation
    (part.cardinality === 'multiple' || part.cardinality === '2');

   */
  visitPartValues(part, localPartVisitor, {scopes: [[]]}, 'single');
  visitPartValues(part, relatedPartVisitor, {scopes: [[]]}, 'recursive');

  return {
    local,
    global,
    related,
  };
};
