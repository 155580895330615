import {assert} from 'assert-ts';
import {Data, DataValue} from '../types';
import {evaluateByPathKeys} from './evaluateByPathKeys';
import {getPathKeys} from './getPathKeys';

export const evaluateByPath = (
  /**
   * dot-separator: '', 'root', 'root.child', 'root.child[0].item'
   **/
  contextPath: string,
  scope: Data,
): DataValue => {
  const assertProps = {scope, contextPath};

  assert(
    scope,
    'evaluateByPath: scope must be given to evaluate context path',
    assertProps,
  );
  return evaluateByPathKeys(getPathKeys(contextPath), scope, assertProps);
};
