import {createContext, useContext, useMemo} from 'react';
import {assert} from 'assert-ts';
import {Schema, Valx} from 'schemaDefinition/types';

export type SchemaScopeContextV2Type = {
  schema: Schema<Valx>;
  valuePath: string;
};

export const SchemaGlobalScopeContextV2 =
  createContext<SchemaScopeContextV2Type>({
    schema: {name: 'noSchema', parts: []},
    valuePath: '',
  });

export const SchemaGlobalScopeContextV2Provider: React.FC<
  React.PropsWithChildren<{
    schema?: SchemaScopeContextV2Type['schema'];
    valuePath?: SchemaScopeContextV2Type['valuePath'];
  }>
> = ({schema, valuePath, children}) => {
  const outer = useContext(SchemaGlobalScopeContextV2);
  const value = useMemo(
    () => ({
      schema: schema ?? outer.schema,
      valuePath: valuePath ?? outer.valuePath,
    }),
    [outer.schema, outer.valuePath, schema, valuePath],
  );

  return (
    <SchemaGlobalScopeContextV2.Provider value={value}>
      {children}
    </SchemaGlobalScopeContextV2.Provider>
  );
};

export const useSchemaGlobalScopeContextV2 = (): SchemaScopeContextV2Type => {
  const value = useContext(SchemaGlobalScopeContextV2);
  assert(
    value.schema.name !== 'noSchema',
    'useSchemaGlobalScopeContextV2: schema expected',
  );
  return value;
};

export const SchemaLocalScopeContextV2 = createContext<
  SchemaScopeContextV2Type | undefined
>(undefined);

export const SchemaLocalScopeContextV2Provider: React.FC<
  React.PropsWithChildren<SchemaScopeContextV2Type>
> = ({schema, valuePath, children}) => {
  const value = useMemo(
    () => ({
      schema,
      valuePath,
    }),
    [schema, valuePath],
  );

  return (
    <SchemaLocalScopeContextV2.Provider value={value}>
      {children}
    </SchemaLocalScopeContextV2.Provider>
  );
};

export const useSchemaLocalScopeContextV2 = (): SchemaScopeContextV2Type => {
  return assert(
    useContext(SchemaLocalScopeContextV2),
    'useSchemaLocalScopeContextV2: context expected',
  );
};
