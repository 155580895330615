/**
 * Edit selected thesaurus categories for field (Tema, Emner, GREP-codes or Sjanger/form)
 *
 * State:
 * (- Closed)
 * (- Open)
 *   - Browse/Search view
 *   - Selected categories view
 *   - Substates:
 *   	[NotSearching, Searching] x [TopLevel, Category]
 *   	- NotSearching,
 *   	  Search field empty
 *   	  - Actions:
 *   	    - Enter search value => state Searching
 *   	- Searching,
 *   	  - Search field non-empty (except when given focus node has duplicates)
 *   	  - Actions:
 *   	    - Change search value -> update search result
 *   	    - Clear search value => state NotSearching
 * 	  - TopLevel:
 *   	  - Full TopLevel view when NotSearching
 *   	  - TopLevel guide when NotSearching
 *   	  - TopLevel results view when Searching (no guide)
 *   	  - Actions:
 *   	    - Goto category => state Category
 *   	    - Goto search result => state Category [FocusTerm]
 *     - Category [NotSearching]:
 *       - Substates: [NoFocusTerm, FocusTerm]
 *       - Category view, with highlight when FocusTerm
 *       - Category guide when NoFocusTerm
 *       - Term details when FocusTerm
 *         - Button: Select/Remove/Replace
 *       - Actions:
 *         - Goto TopLevel => state TopLevel
 *         - Expand term -> show subterms
 *          - Collapse term -> hide subterms => state NoFocusTerm if focus term was subterm, reset focus term
 *         - Click term => state FocusTerm, set focus term
 *         - Select term -> set term in selected categories
 *         - Remove term -> remove term from selected category
 *         - Replace term -> replace term in selected category
 *     - Category [Searching]:
 *       - Ditto
 *       - Highlight søketreff med teller på overornda noder
 */
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {assert} from 'assert-ts';
import {ThesaurusAction} from 'types';
import {
  Thesaurus,
  ThesaurusId,
  ThesaurusNode,
  ThesaurusNodeType,
} from 'api/types';
import {SetSourceValue} from 'sceneExtensions/types';
import {ShowThesaurusCode, ThesaurusValue} from 'schemaDefinition/types';
import {SearchMatch, SearchResult} from 'services/thesaurus/types';
import {
  findNodeWithAncestors,
  getNodeDisplayCode,
  getThesaurusMaps,
} from 'services/thesaurus/functions';
import {moveItem, noOp} from 'services/utils';
import {SelectAction} from '../types';
import {
  addNodeIdToEditValue,
  getSelectAction,
  mapNodeIdsToThesaurusValue,
  removeNodeIdToThesaurusValue,
  removeTimestampSuffix,
  searchThesaurus,
} from '../functions';
import {mapThesaurusValueToNodeIds} from '../functions/mapThesaurusValueToNodeIds';

let timestamp = 0;

type SearchState = 'Searching' | 'NotSearching';
type NavigationState = 'TopLevels' | 'Category';

export type ThesaurusEditState = {
  search: SearchState;
  navigation: NavigationState;
};

export type ThesaurusEditStateContextType = {
  state: ThesaurusEditState;
  thesaurusId: ThesaurusId;
  thesaurus: Thesaurus;
  getDisplayCode: (node: ThesaurusNode) => string | undefined;
  // General
  searchQuery?: string;
  setSearchQuery: (value: string) => void;
  /**
   * When searchValue is empty (e.g. not yet searched), each group will be undefined.
   * Otherwise, result will be an grouped agents.
   */
  searchResult?: SearchResult;

  gotoSearchMatch: (match: SearchMatch) => void;

  /**
   * Thesaurus toplevel node to show
   */
  categoryId?: string;

  gotoCategory: (categoryId: string, nodeId?: string) => void;
  gotoTopLevels: () => void;

  /**
   * Thesaurus node having focus
   */
  focusNodeId?: string;

  gotoFocusNodeId: (nodeId: string) => void;

  focusNodeAction?: SelectAction;

  /**
   * Value currently being edited
   */
  thesaurusEditValue: ThesaurusValue | undefined;

  /**
   * Adds node, removes any nodes in conflict with the added node, i.e.
   * ancestor or decendant nodes.
   */
  addNodeId: (nodeId: string) => void;
  removeNodeId: (nodeId: string) => void;

  close: () => void;
};

export const ThesaurusEditStateContext = createContext<
  ThesaurusEditStateContextType | undefined
>(undefined);

// const debouncedSearch = debounce(searchThesaurus, 300, {
//   trailing: true,
// });

export const useThesaurusEditStateContextProviderValue = (
  thesaurus: Thesaurus,
  showCodes: ShowThesaurusCode,
  /**
   * Types of nodes that may be selected, also, only these nodes
   * will be shown with code
   */
  selectableNodeTypes: ThesaurusNodeType[] | undefined,
  /**
   * Selected codes, if any. If thesaurus contains duplicate codes,
   * i.e. nodes with different id but same code, only one
   * of each code should be given
   */
  thesaurusValue: ThesaurusValue | undefined,
  givenFocusNodeCodeWithTimestamp: string | undefined,
  /**
   * If thesaurus contains duplicate codes, only one code will be
   * set for a code with duplicates
   */
  onSetSourceThesaurusValue: SetSourceValue<ThesaurusValue, ThesaurusAction>,
  onClose: () => void,
): ThesaurusEditStateContextType => {
  // Current state of thesaurus edit
  const [state, setState] = useState<ThesaurusEditState>({
    search: 'NotSearching',
    navigation: 'TopLevels',
  });

  const mapsRef = useRef(getThesaurusMaps(thesaurus));

  // Keep track of timestamp of source value (thesaurusValue) and
  // edit value to avoid cyclic updates, i.e. don't update
  // source value when source value's timestamp is newer than
  // edit value's timestamp
  // TODO: should this be initialized to 1, to reflect that current edit value is set from source value?
  const thesaurusValueTimestampRef = useRef(0);
  const nodeIdsEditValueTimestampRef = useRef(0);

  /**
   * Node ids currently being edited, mapped from thesaurus value (codes)
   */
  const [nodeIdsEditValue, setNodeIdsEditValue] = useState<string[]>(
    mapThesaurusValueToNodeIds(thesaurusValue, mapsRef.current.codeToIds),
  );
  const [searchQuery, setSearchQuery] = useState<string>();
  const searchQueryRef = useRef<typeof searchQuery>(searchQuery);

  const [categoryId, setCategoryId] = useState<string | undefined>();
  const [focusNodeId, setFocusNodeId] = useState<string | undefined>();
  const [focusNodeAction, setFocusNodeAction] = useState<
    SelectAction | undefined
  >();

  const [searchResult, setSearchResult] = useState<SearchResult | undefined>();

  const handleGotoSearchMatch = useCallback((match: SearchMatch) => {
    setState(old => ({
      ...old,
      navigation: 'Category',
    }));

    // Use root node as category id. Use current if no ancestors as we probably are on the root node.
    const catId = match.ancestors[1] ? match.ancestors[1].id : match.node.id;
    setCategoryId(catId);
    setFocusNodeId(match.node.id);
  }, []);

  const handleSetSourceThesaurusValue = useCallback(
    (nodeIds: string[]) => {
      onSetSourceThesaurusValue(
        mapNodeIdsToThesaurusValue(nodeIds, mapsRef.current.idToCode),
        'set',
        'keepOpen',
      );
    },
    [onSetSourceThesaurusValue],
  );

  const handleGotoCategory = useCallback(
    (categoryId: string, nodeId?: string) => {
      setState(old => ({
        search: old.search,
        navigation: 'Category',
      }));

      setCategoryId(categoryId);
      setFocusNodeId(nodeId);
    },
    [],
  );

  const handleGotoFocusNodeId = useCallback(
    (nodeId: string) => {
      if (nodeId && nodeId !== focusNodeId) {
        const categoryId = findNodeWithAncestors(nodeId, thesaurus)
          ?.ancestors[1].id;
        if (categoryId) {
          handleGotoCategory(categoryId, nodeId);
        }
      }
    },
    [focusNodeId, handleGotoCategory, thesaurus],
  );

  const handleGotoTopLevels = useCallback(() => {
    setState(old => ({
      search: old.search,
      navigation: 'TopLevels',
    }));

    setCategoryId(undefined);
    setFocusNodeId(undefined);
  }, []);

  const handleAddNodeId = useCallback(
    (nodeId: string) => {
      setNodeIdsEditValue(old => {
        let newValue = addNodeIdToEditValue(nodeId, old, thesaurus);
        mapsRef.current.duplicateIdToIds.get(nodeId)?.forEach(duplicateId => {
          newValue = addNodeIdToEditValue(duplicateId, newValue, thesaurus);
        });

        return newValue;
      });

      nodeIdsEditValueTimestampRef.current = timestamp++;
    },
    [thesaurus],
  );

  const handleRemoveNodeId = useCallback((nodeId: string) => {
    setNodeIdsEditValue(old => {
      let newValue = removeNodeIdToThesaurusValue(nodeId, old);
      mapsRef.current.duplicateIdToIds.get(nodeId)?.forEach(duplicateId => {
        newValue = removeNodeIdToThesaurusValue(duplicateId, newValue);
      });

      return newValue;
    });
    nodeIdsEditValueTimestampRef.current = timestamp++;
  }, []);

  const handleMoveNodeAtIdx = useCallback(
    (oldIndex: number, newIndex: number) => {
      setNodeIdsEditValue(old => {
        if (
          !assert.soft(
            old,
            'useThesaurusEditStateContextProviderValue.handleMoveNodeAtIdx: old value expected',
          )
        ) {
          return old;
        }

        return moveItem(old, oldIndex, newIndex);
      });
      nodeIdsEditValueTimestampRef.current = timestamp++;
    },
    [],
  );

  const getDisplayCode = useCallback(
    (node: ThesaurusNode) => {
      return getNodeDisplayCode(node, showCodes, selectableNodeTypes);
    },
    [selectableNodeTypes, showCodes],
  );

  // When search query is changed
  // => empty query: set state 'not searching'
  // => query: update search result, set state 'searching'
  useEffect(() => {
    if (searchQuery === searchQueryRef.current) {
      return;
    }

    searchQueryRef.current = searchQuery;

    if (!searchQuery) {
      if (state.search === 'Searching') {
        setState(old => ({
          search: 'NotSearching',
          navigation: old.navigation,
        }));
        setSearchResult(undefined);
      }
      return;
    }

    setState(() => ({
      search: 'Searching',
      navigation: 'TopLevels',
    }));

    setSearchResult({matches: []});

    if (searchQuery.length >= 2) {
      const search = searchThesaurus(
        searchQuery,
        thesaurusValue,
        getDisplayCode,
        thesaurus,
      );
      if (search) setSearchResult({matches: search});
    }
  }, [
    selectableNodeTypes,
    getDisplayCode,
    searchQuery,
    showCodes,
    state,
    thesaurus,
    thesaurusValue,
  ]);

  // When source value is changed, e.g. other field selected
  // => set edit value accordingly
  useEffect(() => {
    thesaurusValueTimestampRef.current = timestamp++;
    setNodeIdsEditValue(() =>
      mapThesaurusValueToNodeIds(thesaurusValue, mapsRef.current.codeToIds),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thesaurusValue]);

  // When edit value is changed,
  // => update source value
  useEffect(() => {
    // Only update source value if edit value is newer
    if (
      nodeIdsEditValueTimestampRef.current > thesaurusValueTimestampRef.current
    ) {
      handleSetSourceThesaurusValue(nodeIdsEditValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSetSourceThesaurusValue, nodeIdsEditValue]);

  // When givenFocusNodeCodeWithTimestamp is changed
  // => - no duplicates: set focusNodeId accordingly
  //    - duplicates: search result with all duplicates
  useEffect(() => {
    const code = removeTimestampSuffix(givenFocusNodeCodeWithTimestamp);
    if (code) {
      if ((mapsRef.current.codeToIds.get(code)?.length ?? 0) > 1) {
        setState(() => ({
          search: 'Searching',
          navigation: 'TopLevels',
        }));

        const allMatches = searchThesaurus(
          code,
          thesaurusValue,
          getDisplayCode,
          thesaurus,
        );
        const codeMatches = allMatches.filter(m => m.node.code === code);
        setSearchResult({
          matches: codeMatches,
        });
        setFocusNodeId(undefined);
      } else {
        handleGotoFocusNodeId(code);
      }
    }
    // Only trigger on givenFocusNodeCodeWithTimestamp change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [givenFocusNodeCodeWithTimestamp]);

  // When focus node or edit value is changed,
  // => update select action
  useEffect(() => {
    if (focusNodeId) {
      const action = getSelectAction(
        focusNodeId,
        nodeIdsEditValue,
        thesaurus,
        selectableNodeTypes,
      );
      setFocusNodeAction(action);
    } else {
      setFocusNodeAction(undefined);
    }
  }, [focusNodeId, selectableNodeTypes, thesaurus, nodeIdsEditValue]);

  return useMemo(() => {
    return {
      state,
      thesaurus,
      getDisplayCode,
      thesaurusId: thesaurus.id,
      searchQuery,
      setSearchQuery,
      searchResult,
      searchResultPage: searchResult,
      searchResultPageNumber: 1,
      gotoSearchResultPage: noOp,
      gotoSearchMatch: handleGotoSearchMatch,
      categoryId,
      gotoCategory: handleGotoCategory,
      gotoTopLevels: handleGotoTopLevels,
      focusNodeId,
      gotoFocusNodeId: handleGotoFocusNodeId,
      focusNodeAction,
      thesaurusEditValue: nodeIdsEditValue,
      addNodeId: handleAddNodeId,
      removeNodeId: handleRemoveNodeId,
      moveNodeAtIdx: handleMoveNodeAtIdx,
      close: onClose,
    };
  }, [
    state,
    thesaurus,
    getDisplayCode,
    searchQuery,
    searchResult,
    handleGotoSearchMatch,
    categoryId,
    handleGotoCategory,
    handleGotoTopLevels,
    focusNodeId,
    handleGotoFocusNodeId,
    focusNodeAction,
    nodeIdsEditValue,
    handleAddNodeId,
    handleRemoveNodeId,
    handleMoveNodeAtIdx,
    onClose,
  ]);
};

export const useThesaurusEditStateContext =
  (): ThesaurusEditStateContextType => {
    return assert(
      useContext(ThesaurusEditStateContext),
      'ThesaurusEditStateContext: context expected',
    );
  };
