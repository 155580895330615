import {assert} from 'assert-ts';
import {Condition, Part, PartExpand, Schema, SeparatedParts} from '../types';
import {ValueContext, ValueVisitor} from './types';
import {
  isAndCondition,
  isBooleanCondition,
  isComparisonCondition,
  isLengthCondition,
  isNotCondition,
  isOrCondition,
  isRangeCondition,
  isRegexCondition,
} from './isConditionType';
import {isFieldRequiredConditional} from './isFieldRequiredConditional';

type Nesting = 'single' | 'recursive';

export const visitSchemaValues = <TValSource, TValTarget>(
  mutableSchema: Schema<TValSource & TValTarget>,
  visitor: ValueVisitor<TValSource, TValTarget>,
  context?: ValueContext,
) => {
  const ctx = context ?? {scopes: []};
  ctx.scopes.push([]);
  visitPartsValues(mutableSchema.parts, visitor, ctx, false);
  ctx.scopes.pop();
};

export const visitPartValues = <TValSource, TValTarget>(
  part: Part<TValSource & TValTarget>,
  visitor: ValueVisitor<TValSource, TValTarget>,
  context: ValueContext,
  nesting: Nesting = 'recursive',
) => {
  visitConditionValues(part.listValidation, visitor, context);

  if (isFieldRequiredConditional(part.required)) {
    visitConditionValues(part.required.required, visitor, context);
    visitConditionValues(part.required.should, visitor, context);
  }

  switch (part.type) {
    case 'bool':
    case 'html':
    case 'codelist|text':
    case 'nameVariant': {
      return;
    }
    case 'int':
    case 'text':
    case 'textarea':
    case 'year':
    case 'date':
    case 'yearOrDate':
    case 'codelist':
    case 'linkedAgent':
    case 'linkedLiterary':
    case 'thesaurus': {
      return visitConditionValues(part.validation, visitor, context);
    }
    case 'expand': {
      visitPartExpandValues(part, visitor, context, nesting);
      return;
    }
    case 'schema': {
      if (nesting === 'recursive') {
        visitSchemaValues(part, visitor, context);
      }
      return;
    }
  }

  assert(false, 'visitPart');
};

export const visitPartsValues = <TValSource, TValTarget>(
  parts: SeparatedParts<TValSource & TValTarget> = [],
  visitor: ValueVisitor<TValSource, TValTarget>,
  context: ValueContext,
  referableOutside: boolean,
) => {
  let ctx = context;
  parts.forEach(part => {
    if (Array.isArray(part)) {
      visitPartsValues(part, visitor, context, referableOutside);
    } else if (part.type !== 'separator') {
      ctx = extendContext(ctx, part, referableOutside);
      visitPartValues(part, visitor, ctx);
    }
  });
};

export const visitConditionValues = <TValSource, TValTarget>(
  condition:
    | Condition<TValSource & TValTarget>
    | Condition<TValSource & TValTarget>[]
    | undefined,
  visitor: ValueVisitor<TValSource, TValTarget>,
  context: ValueContext,
) => {
  if (condition === undefined) return;
  // Array?
  if (Array.isArray(condition)) {
    condition.forEach(c => visitConditionValues(c, visitor, context));
    return;
  }

  if (isRangeCondition(condition)) {
    visit(condition, 'arg1', visitor, context);
    visit(condition, 'min', visitor, context);
    visit(condition, 'max', visitor, context);
    return;
  }

  if (isLengthCondition(condition)) {
    visit(condition, 'arg1', visitor, context);
    visit(condition, 'minLength', visitor, context);
    visit(condition, 'maxLength', visitor, context);
    return;
  }

  if (isComparisonCondition(condition)) {
    visit(condition, 'arg1', visitor, context);
    visit(condition, 'arg2', visitor, context);
    return;
  }

  if (isBooleanCondition(condition) || isRegexCondition(condition)) {
    visit(condition, 'arg', visitor, context);
    return;
  }

  if (
    isNotCondition(condition) ||
    isOrCondition(condition) ||
    isAndCondition(condition)
  ) {
    visitConditionValues(condition.arg, visitor, context);
    return;
  }

  assert.soft(false, 'visitSchemaCondition: unknown condition', {condition});

  return false;
};

export const visitPartExpandValues = <TValSource, TValTarget>(
  expand: PartExpand<TValSource & TValTarget>,
  visitor: ValueVisitor<TValSource, TValTarget>,
  context: ValueContext,
  nesting: Nesting = 'recursive',
) => {
  expand.when.forEach(w => {
    visitConditionValues(w.condition, visitor, context);
    if (nesting === 'recursive') {
      visitPartsValues(w.parts, visitor, context, false);
    }
  });

  if (nesting === 'recursive') {
    visitPartsValues(expand.default, visitor, context, false);
  }
};

const extendContext = <TValSource, TValTarget>(
  context: ValueContext,
  part: Part<TValSource & TValTarget>,
  /** true: add to given context, false: create new and extend */
  referableOutside: boolean,
): ValueContext => {
  const {type, name} = part;
  if (type === 'schema' || !name) return context;

  const ctx = referableOutside
    ? context
    : (JSON.parse(JSON.stringify(context)) as ValueContext);
  ctx.scopes[ctx.scopes.length - 1].push(name);
  return ctx;
};

const visit = <T, TKey extends keyof T, TValSource, TValTarget>(
  objWithVals: T,
  key: TKey,
  visitor: ValueVisitor<TValSource, TValTarget>,
  context: ValueContext,
) => {
  const val = objWithVals[key];
  if (val !== undefined) {
    visitor(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      val,
      newVal => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        objWithVals[key] = newVal;
      },
      context,
    );
  }
};
