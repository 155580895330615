import {assert} from 'assert-ts';
import {Thesaurus} from 'api/types';
import {getSelectAction} from './getSelectAction';

export const addNodeIdToEditValue = (
  nodeId: string,
  value: string[],
  thesaurus: Thesaurus,
): string[] => {
  const action = getSelectAction(nodeId, value, thesaurus, undefined);
  if (!action) {
    return value;
  }

  assert(
    action.type === 'select' ||
      action.type === 'swap' ||
      action.type === 'remove',
    'addNodeIdToThesaurusValue: action select or swap expected',
  );

  if (action.type === 'select' || action.type === 'remove') {
    return [...value, nodeId];
  } else {
    return [...value.filter(id => !action.relatedNodeIds.includes(id)), nodeId];
  }
};
