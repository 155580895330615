import {valueAsBool} from 'services/utils';
import {BooleanCondition, Data, DataValue, RefPath, Valx} from '../types';
import {evaluateValxWithDefault} from './evaluateValxWithDefault';

export const evaluateBooleanCondition = (
  condition: BooleanCondition<Valx>,
  /**
   * Path from global scope to evaluation context
   */
  contextPath: RefPath,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  defaultValue?: DataValue | undefined,
): boolean => {
  const arg = evaluateValxWithDefault(
    condition.arg,
    contextPath,
    localScope,
    globalScope,
    relatedScope,
    defaultValue,
  );

  const boolValue = valueAsBool(arg);
  return boolValue;
};
