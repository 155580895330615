import React, {useMemo} from 'react';
import {LocalizationProvider} from '@mui/x-date-pickers-pro';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {ThesaurusId} from 'schemaDefinition/types';
import {isFeatureEnabled} from 'configuration';
import {CodeListsGate} from 'services/codeLists';
import {useMetadataCodelistIds} from 'services/data/metadata';
import {MetadataGate} from 'services/data/metadata/components/MetadataGate';
import {ThesaurusesGate} from 'services/thesaurus';
import {MockContext} from 'services/utils/contexts';
import {
  useLinkedValueSearchSceneExtensionCore,
  useThesaurusEditSceneExtensionCore,
} from 'sceneExtensions';
import {
  SceneExtensionsContext,
  useSceneExtensionsContextProviderValue,
} from 'sceneExtensions/contexts';
import {WorkScene} from './WorkScene';
import {MetadataEditStateGate} from './components';
import {
  MetadataOperationsContextProvider,
  UpdateMetadataParamsContext,
  WorkToggleContext,
  useUpdateMetadataParamsContextProviderValue,
  useWorkToggleContextProviderValue,
} from './contexts';

dayjs.extend(utc);

const thesaurusIds: ThesaurusId[] = [
  'thema',
  'bokbasen',
  'grep',
  ...((isFeatureEnabled('genreAndFormThesaurus')
    ? ['genreandform']
    : []) as ThesaurusId[]),
];

export const UpdateMetadataScene: React.FC = () => {
  const paramsContext = useUpdateMetadataParamsContextProviderValue();

  const codelistIds = useMetadataCodelistIds();

  const thesaurusEditExtension = useThesaurusEditSceneExtensionCore();
  const linkedValueSearchExtension = useLinkedValueSearchSceneExtensionCore();
  const extensions = useMemo(
    () => [thesaurusEditExtension, linkedValueSearchExtension],
    [linkedValueSearchExtension, thesaurusEditExtension],
  );

  const sceneExtensionContext =
    useSceneExtensionsContextProviderValue(extensions);

  const workToggleValue = useWorkToggleContextProviderValue();

  // Load codelists, thesauruses and metadata, then initialize MetadataEditState
  return (
    <UpdateMetadataParamsContext.Provider value={paramsContext}>
      <MockContext.Provider value={paramsContext.mock}>
        <CodeListsGate codeLists={codelistIds}>
          <ThesaurusesGate thesauruses={thesaurusIds}>
            <MetadataGate workId={paramsContext.workId}>
              <MetadataEditStateGate>
                <MetadataOperationsContextProvider>
                  <SceneExtensionsContext.Provider
                    value={sceneExtensionContext}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <WorkToggleContext.Provider value={workToggleValue}>
                        <WorkScene />
                      </WorkToggleContext.Provider>
                    </LocalizationProvider>
                  </SceneExtensionsContext.Provider>
                </MetadataOperationsContextProvider>
              </MetadataEditStateGate>
            </MetadataGate>
          </ThesaurusesGate>
        </CodeListsGate>
      </MockContext.Provider>
    </UpdateMetadataParamsContext.Provider>
  );
};
