import React, {useCallback, useMemo, useState} from 'react';
import {Grid} from '@mui/material';
import {assert} from 'assert-ts';
import {CodeListFieldProps} from 'components/fields/codelist/types';
import {TextFieldProps} from 'components/fields/types';
import {
  EmptyLinkedLiterary,
  FieldError,
  LinkedLiterary as LinkedLiteraryType,
  PartLinkedLiterary,
  Valx,
  VerifiedLinkedValueLink,
} from 'schemaDefinition/types';
import {useLocalization} from 'localization';
import {
  assertAsLinkedValue,
  evaluateFieldRequired,
} from 'schemaDefinition/functions';
import {assertAsLinkedLiterary} from 'schemaDefinition/functions/assertAsLinkedLiterary';
import {RoleEntityCode} from 'schemaDefinition/linkTypes';
import {isNullish} from 'services/utils';
import {CodeListField, LinkedField, TextField} from 'components/fields';
import {useFieldId, useLinkTargetMainAndSubTypes} from 'schema/form/hooks';
import {getRoleEntityFromNewLink} from 'schemas/functions/getRoleFromNewLink';
import {useLinkedRoleCode} from 'schemas/links/hooks/useLinkedRoleCode';
import {useLinkedRoleCodelist} from 'schemas/links/hooks/useLinkedRoleCodelist';
import {BaseFieldProps} from './types';
import {useDataFormContext} from '../../contexts';
import {formatLinkedValueLink} from '../../functions/formatLinkedValueLink';
import {toFieldError} from '../../functions/validators';
import {
  validateSingleLinkedLiteraryLink,
  validateSingleLinkedLiteraryLinkProp,
  validateSingleLinkedLiteraryRole,
} from '../../functions/validators/validateLinkedLiterary';

type Props = BaseFieldProps & {
  part: PartLinkedLiterary<Valx>;
};

const emptyLink: EmptyLinkedLiterary = {
  link: {
    linkStatus: 'empty',
  },
};

const setLink = (
  current: LinkedLiteraryType | null | undefined,
  link: VerifiedLinkedValueLink | undefined,
): LinkedLiteraryType => {
  return {
    ...(current ?? emptyLink),
    link: link ?? emptyLink.link,
  } as LinkedLiteraryType;
};

const setRole = (
  current: LinkedLiteraryType | undefined | null,
  role: RoleEntityCode | null,
): LinkedLiteraryType => {
  return {
    ...(current ?? emptyLink),
    role: (role ?? undefined) as RoleEntityCode,
  };
};

const setLinkNumberInSeries = (
  current: LinkedLiteraryType | undefined | null,
  numberInSeries: string | null,
): LinkedLiteraryType => {
  return {...(current ?? emptyLink), numberInSeries};
};

export const LinkedLiterary: React.FC<Props> = ({
  part,
  valuePath,
  value,
  setFieldValue,
  mode,
  showWhenReadonlyAndEmpty,
  label,
  focusableId: _,
  scope,
  globalScope,
  relatedScope,
}) => {
  const {t} = useLocalization();
  const roleCodelist = useLinkedRoleCodelist(part.roleCodelistId);
  const [roleVisited, setRoleVisited] = useState(false);
  const [linkVisited, setLinkVisited] = useState(false);
  const [linkPropVisited, setLinkPropVisited] = useState(false);
  const handleSetRoleVisited = useCallback(() => {
    setRoleVisited(true);
  }, []);
  const handleSetLinkVisited = useCallback(() => {
    setLinkVisited(true);
  }, []);
  const handleSetLinkPropVisited = useCallback(() => {
    setLinkPropVisited(true);
  }, []);

  const required = useMemo(() => {
    return evaluateFieldRequired(
      part.required,
      valuePath,
      scope,
      globalScope,
      relatedScope,
      value,
    );
  }, [globalScope, part.required, relatedScope, scope, value, valuePath]);

  const {showErrors} = useDataFormContext();
  const {roleError, linkError, linkPropError} = useMemo((): {
    roleError: FieldError;
    linkError: FieldError;
    linkPropError: FieldError;
  } => {
    const codelistMap = {[part.roleCodelistId]: roleCodelist};
    const roleError = part.linkRole
      ? false
      : showErrors || roleVisited
        ? toFieldError(
            validateSingleLinkedLiteraryRole(
              part,
              value,
              valuePath,
              scope,
              globalScope,
              relatedScope,
              codelistMap,
            ).valid,
          )
        : false;

    const linkError =
      showErrors || linkVisited
        ? toFieldError(
            validateSingleLinkedLiteraryLink(
              part,
              assertAsLinkedValue(value, valuePath),
              valuePath,
              scope,
              globalScope,
              relatedScope,
              codelistMap,
            ).valid,
          )
        : false;

    const linkPropError =
      showErrors || linkPropVisited
        ? toFieldError(
            validateSingleLinkedLiteraryLinkProp(
              part,
              assertAsLinkedValue(value, valuePath),
              valuePath,
              scope,
              globalScope,
              relatedScope,
              codelistMap,
            ).valid,
          )
        : false;
    return {roleError, linkError, linkPropError};
  }, [
    globalScope,
    linkPropVisited,
    linkVisited,
    part,
    relatedScope,
    roleCodelist,
    roleVisited,
    scope,
    showErrors,
    value,
    valuePath,
  ]);

  const linkedValue = assertAsLinkedLiterary(value, valuePath);
  const fieldId = useFieldId(valuePath);
  const code = useLinkedRoleCode(
    part.linkRole ?? linkedValue?.role,
    roleCodelist,
  );

  type LinkPropProps = Omit<TextFieldProps, 'value' | 'onChange' | 'onBlur'>;
  const {roleProps, linkPropertyProps, formattedValue} = useMemo((): {
    roleProps: Partial<CodeListFieldProps>;
    linkPropertyProps?: LinkPropProps;
    formattedValue: string;
  } => {
    const roleProps: Partial<CodeListFieldProps> = {
      placeholder: t('link.role.placeholder'),
    };
    const linkPropertyProps: LinkPropProps | undefined = code?.linkProperty
      ? {
          name: `valuePath.${code.linkProperty.name}`,
          type: code.linkProperty.type === 'int' ? 'number' : 'text',
          placeholder: t('link.series.part.placeholder'),
          required: 'should',
        }
      : undefined;
    return {
      roleProps,
      linkPropertyProps,
      formattedValue: formatLinkedValueLink(linkedValue?.link) ?? '',
    };
  }, [code, linkedValue, t]);

  const handleSetLink = useCallback(
    (link: VerifiedLinkedValueLink | undefined) => {
      if (link) {
        const updatedRole = getRoleEntityFromNewLink(
          part.linkRole,
          linkedValue?.role,
          link,
          roleCodelist,
        );
        const valueWithRole = updatedRole
          ? setRole(linkedValue, updatedRole)
          : linkedValue;
        const newValue = setLink(valueWithRole, link);
        setFieldValue(valuePath, newValue);
      } else {
        const valueWithRole = part.linkRole
          ? setRole(linkedValue, part.linkRole)
          : linkedValue;
        const newValue = setLink(valueWithRole, undefined);
        setFieldValue(valuePath, newValue);
      }
    },
    [linkedValue, part.linkRole, roleCodelist, setFieldValue, valuePath],
  );
  const handleSetRole = useCallback(
    (role: string | string[] | null) => {
      assert(!Array.isArray(role), 'handleSetRole: non-array expected');
      const newValue = setRole(linkedValue, role as RoleEntityCode | null);
      setFieldValue(valuePath, newValue);
    },
    [linkedValue, valuePath, setFieldValue],
  );

  const handleSetLinkProp = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newPropVal =
        e.target.value === ''
          ? null
          : isNullish(e.target.value)
            ? null
            : e.target.value;
      const newValue = setLinkNumberInSeries(linkedValue, newPropVal);
      setFieldValue(valuePath, newValue);
    },
    [linkedValue, valuePath, setFieldValue],
  );

  const [mainType, subTypes] = useLinkTargetMainAndSubTypes(code?.targetEntity);
  const showCodelistField = !part.linkRole;

  return (
    <Grid container justifyContent={'space-between'} spacing={2}>
      {showCodelistField ? (
        <Grid item xs={5} data-cy={'link-role'}>
          <CodeListField
            label={label}
            value={linkedValue?.role}
            cardinality={'single'}
            readonly={mode === 'read-only'}
            showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
            error={roleError}
            name={valuePath}
            id={fieldId}
            options={roleCodelist.codes}
            fullWidth
            {...roleProps}
            onChange={handleSetRole}
            onBlur={handleSetRoleVisited}
          />
        </Grid>
      ) : null}
      <Grid
        item
        xs={
          showCodelistField
            ? linkPropertyProps
              ? 5
              : 7
            : linkPropertyProps
              ? 9
              : 12
        }
        data-cy={'link-id'}>
        <LinkedField
          name={fieldId}
          label={label}
          required={required}
          readonly={mode === 'read-only'}
          showWhenReadonlyAndEmpty={showWhenReadonlyAndEmpty}
          entity={mainType}
          entitySubtypes={subTypes}
          error={linkError}
          link={linkedValue?.link}
          formattedValue={formattedValue}
          onChange={handleSetLink}
          onBlur={handleSetLinkVisited}
        />
      </Grid>
      {linkPropertyProps ? (
        <Grid item xs={showCodelistField ? 2 : 3} data-cy={'link-number'}>
          <TextField
            {...linkPropertyProps}
            value={linkedValue?.numberInSeries ?? null}
            readonly={mode === 'read-only'}
            showWhenReadonlyAndEmpty={true}
            error={linkPropError}
            onChange={handleSetLinkProp}
            onBlur={handleSetLinkPropVisited}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};
