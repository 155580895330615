import {CodeListMap} from 'api/types';
import {
  Data,
  DataValue,
  PartYearOrDate,
  Valx,
  YearOrDateValue,
} from 'schemaDefinition/types';
import {isNullish} from 'services/utils';
import {ValidationResult} from './types';
import {evaluateFieldRequired} from '../../../../schemaDefinition';
import {fail} from './fail';
import {validResult} from './validResult';
import {isValidDate} from './validateDate';
import {validateValueType} from './validateValueType';
import {isValidYear} from './validateYear';
import {validateYearOrDateValidations} from './validateYearOrDateValidations';
import {warning} from './warning';

export const validateYearOrDate = (
  part: PartYearOrDate<Valx>,
  value: DataValue | undefined,
  valuePath: string,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  return validateValueType(
    part,
    validateSingleYearOrDateValue,
    value,
    valuePath,
    localScope,
    globalScope,
    relatedScope,
    undefined,
    aggregateResult,
  );
};

export const validateSingleYearOrDateValue = (
  part: PartYearOrDate<Valx>,
  value: DataValue | undefined,
  valuePath: string,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  _codelistMap: CodeListMap | undefined,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  const yearOrDateValue = value as YearOrDateValue | undefined;

  if (
    isNullish(yearOrDateValue) ||
    (isNullish(yearOrDateValue.year) && isNullish(yearOrDateValue.date))
  ) {
    const required = evaluateFieldRequired(
      part.required,
      valuePath,
      localScope,
      globalScope,
      relatedScope,
      value,
    );
    return required
      ? (required === true ? fail : warning)(
          {
            value,
            valuePath,
            part,
            localScope,
            validation: 'required',
            messageKey: 'required',
          },
          aggregateResult,
        )
      : aggregateResult;
  }

  if (Array.isArray(value) || typeof value !== 'object') {
    return fail(
      {
        value,
        valuePath,
        part,
        localScope,
        validation: 'invalid type',
        messageKey: 'invalidYearOrDate',
      },
      aggregateResult,
    );
  }

  if (!isNullish(yearOrDateValue.year) && !isValidYear(yearOrDateValue.year)) {
    return fail(
      {
        value,
        valuePath,
        part,
        localScope,
        validation: 'invalid type',
        messageKey: 'invalidYearOrDate',
      },
      aggregateResult,
    );
  }

  if (!isNullish(yearOrDateValue.date) && !isValidDate(yearOrDateValue.date)) {
    return fail(
      {
        value,
        valuePath,
        part,
        localScope,
        validation: 'invalid type',
        messageKey: 'invalidYearOrDate',
      },
      aggregateResult,
    );
  }

  if (part.validation) {
    return validateYearOrDateValidations(
      value,
      part.validation,
      valuePath,
      part,
      localScope,
      globalScope,
      relatedScope,
      aggregateResult,
    );
  }

  return aggregateResult;
};
