import {CodeList, ManifestationV4} from 'api/types';
import {getProductFormText} from 'services/data/metadata/functions';

export const getManifestationHeaderTitle = (
  data?: ManifestationV4,
  productFormCodelist?: CodeList,
  productFormOverrideCodelist?: CodeList,
): string => {
  if (!data) return '';
  const manifestation = data;

  const titles = (manifestation?.mainTitles || []).map(m => m.value);
  const productFormText = getProductFormText(
    manifestation.productForm,
    productFormCodelist,
    productFormOverrideCodelist,
  );

  const fields = [
    ...titles,
    manifestation.isbn,
    productFormText,
    manifestation.publishedYear,
  ].filter(f => !!f);

  return fields.join(' / ');
};
