import {valueAsNumber} from 'services/utils';
import {Data, DataValue, RangeCondition, RefPath, Valx} from '../types';
import {evaluateValx} from './evaluateValx';
import {evaluateValxWithDefault} from './evaluateValxWithDefault';

export const evaluateRangeCondition = (
  condition: RangeCondition<Valx>,
  /**
   * Path from global scope to evaluation context
   */
  contextPath: RefPath,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  localValue: DataValue | undefined,
): boolean => {
  const min = condition.min
    ? valueAsNumber(
        evaluateValx(
          condition.min,
          contextPath,
          localScope,
          globalScope,
          relatedScope,
        ),
      )
    : undefined;
  const max = condition.max
    ? valueAsNumber(
        evaluateValx(
          condition.max,
          contextPath,
          localScope,
          globalScope,
          relatedScope,
        ),
      )
    : undefined;
  const arg1 = valueAsNumber(
    evaluateValxWithDefault(
      condition.arg1,
      contextPath,
      localScope,
      globalScope,
      relatedScope,
      localValue,
    ),
  );

  return (
    arg1 !== undefined &&
    (min === undefined || arg1 >= min) &&
    (max === undefined || arg1 <= max)
  );
};
