import {
  Data,
  DataFormValueType,
  Part,
  PartExpand,
  Schema,
  SeparatedParts,
  Valx,
} from '../types';
import {PartVisitor} from './types';
import {evaluateCondition} from './evaluateCondition';

export const visitVisibleSchemaParts = (
  data: Data,
  relatedScope: Data,
  schema: Schema<DataFormValueType>,
  visitor: PartVisitor<DataFormValueType>,
) => {
  return visitVisibleParts(schema.parts, data, '', data, relatedScope, visitor);
};

export const visitVisibleParts = (
  parts: SeparatedParts<Valx>,
  data: Data,
  dataPath: string,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  visitor: PartVisitor<DataFormValueType>,
) => {
  parts.forEach(part =>
    Array.isArray(part)
      ? visitVisibleParts(
          part,
          data,
          dataPath,
          globalScope,
          relatedScope,
          visitor,
        )
      : part.type !== 'separator'
      ? visitVisiblePart(
          part,
          data,
          dataPath,
          globalScope,
          relatedScope,
          visitor,
        )
      : undefined,
  );
};

export const visitVisiblePart = (
  part: Part<Valx>,
  data: Data,
  dataPath: string,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  visitor: PartVisitor<DataFormValueType>,
) => {
  const partPath = dataPath ? `${dataPath}.${part.name}` : part.name ?? '';

  switch (part.type) {
    case 'schema': {
      visitor(part, partPath);
      visitVisibleParts(
        part.parts,
        data,
        partPath,
        globalScope,
        relatedScope,
        visitor,
      );
      return;
    }

    case 'expand': {
      return visitVisibleExpandPart(
        part,
        data,
        dataPath,
        globalScope,
        relatedScope,
        visitor,
      );
    }

    default: {
      visitor(
        part,
        partPath,
        //context
      );
    }
  }
};

export const visitVisibleExpandPart = (
  part: PartExpand<Valx>,
  data: Data,
  dataPath: string,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  visitor: PartVisitor<DataFormValueType>,
) => {
  // Determine active case, if any
  const activeCase = part.when.find(w =>
    evaluateCondition(
      w.condition,
      dataPath,
      data,
      globalScope,
      relatedScope,
      undefined,
    ),
  );

  const activeParts = activeCase ? activeCase.parts : part.default;
  activeParts
    ? visitVisibleParts(
        activeParts,
        data,
        dataPath,
        globalScope,
        relatedScope,
        visitor,
      )
    : undefined;
};
