import React, {CSSProperties, useMemo} from 'react';
import {Box, Grid, useMediaQuery} from '@mui/material';
import {useTheme} from '@mui/system';
import {DataFormConfiguration} from 'schema/types';
import {isFeatureEnabled} from 'configuration';
import {useLocalization} from 'localization';
import {HeaderHeight, UXUnit} from 'theme/settings';
import {WorkV4} from 'api';
import {
  useGetWorkEditValue,
  useSetWorkEditValue,
  useWorkEditValue,
} from 'services/data';
import {Card, FlexBox, Layout, Spacer, Text} from 'components';
import {DataForm} from 'schema/form';
import {useWorkEditStateContext} from 'scenes/updateMetadata/contexts/WorkEditStateContext';
import {useWorkToggleContext} from '../../contexts';
import {ChangeRequestCard} from '../ChangeRequestCard';
import {CustomAgentActions} from '../CustomAgentActions';
import {WorkCardTitle} from './components/WorkCardTitle';
import {interceptWorkSetFieldValue} from './functions';
import {useWorkLevelChangeRequests} from './hooks';

const EMPTY_RELATED_DATA = {};

type Props = {
  workId: string;
};

const headerHeight = HeaderHeight * 2 + UXUnit;

const cardSx = {
  position: 'sticky',
  top: `${headerHeight}px`,
  maxHeight: `calc(100vh - ${headerHeight + UXUnit * 3}px)`,
  transition: 'all .5s',
};

export const WorkCard: React.FC<Props> = ({workId}) => {
  const {t, tLoose} = useLocalization();
  const WorkFormConfiguration = useMemo(
    (): Partial<DataFormConfiguration<WorkV4>> => ({
      schemaContainers: {
        dewey: 'NoContainer',
        otherTitles: 'NoContainer',
      },
      parts: {
        agents: isFeatureEnabled('unnamedAgent')
          ? {
              label: t('form.global.field.agents.label'),
              renderCustomListActions: (part, props) => (
                <CustomAgentActions part={part} {...props} />
              ),
            }
          : undefined,
      },
      showWhenReadonlyAndEmpty: true,
    }),
    [t],
  );
  const getEditValue = useGetWorkEditValue();
  const setEditValue = useSetWorkEditValue();
  const {schema, readonly, valid, showErrors} = useWorkEditStateContext();
  const changeRequests = useWorkLevelChangeRequests();
  const {workHidden} = useWorkToggleContext();

  const theme = useTheme();

  /**
   * TODO: Fix breakpoints:
   *  The breakpoints here are messed up for some reason, so i define it (1200) manually
   */
  const mdUp = useMediaQuery(theme.breakpoints.up(1200));
  const style: CSSProperties = useMemo(() => {
    const always = {
      width: '100%',
      transition: 'all .5s ease-in-out',
    };

    if (mdUp) {
      return {
        ...always,
        opacity: workHidden ? 0 : 1,
        overflow: workHidden ? 'hidden' : 'inherit',
      };
    }

    return always;
  }, [mdUp, workHidden]);

  return (
    <Card
      color="primary"
      mode="light"
      scrollable={'toggle'}
      sx={cardSx}
      data-cy={'work'}>
      <Card.Header>
        <Layout
          horizontal
          flex={1}
          adjustCenter
          justifyContent={'space-between'}>
          <WorkCardTitle />
          <Layout horizontal adjustCenter adjustRight>
            {showErrors && (valid === 'warning' || valid === 'error') ? (
              <>
                <Text variant="h4" kind={valid}>
                  {tLoose(`form.global.validation.${valid}.label`)}
                </Text>
                <Spacer width={4} />
              </>
            ) : null}
          </Layout>
        </Layout>
      </Card.Header>
      <Card.Content>
        <FlexBox style={style}>
          {changeRequests?.map(wcrId => (
            <Box key={wcrId} component={Grid} item>
              <ChangeRequestCard key={wcrId} changeRequestId={wcrId} />
            </Box>
          ))}

          <DataForm
            id={workId}
            schema={schema}
            configuration={WorkFormConfiguration}
            // TODO: Validate: fix this
            relatedData={EMPTY_RELATED_DATA}
            mode={readonly || (mdUp && workHidden) ? 'read-only' : 'edit'}
            showErrors={showErrors}
            setFieldValueModifier={interceptWorkSetFieldValue}
            getEditValue={getEditValue}
            useEditValue={useWorkEditValue}
            setEditValue={setEditValue}
          />
        </FlexBox>
      </Card.Content>
    </Card>
  );
};
