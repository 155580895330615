import {CodeListMap} from 'api/types';
import {Data, DataValue, PartSchema, Valx} from 'schemaDefinition/types';
import {isNullish} from 'services/utils';
import {ValidateSingleTypeValue, ValidationResult} from './types';
import {fail} from './fail';
import {getValue} from './getValue';
import {validResult} from './validResult';
import {validateSchema} from './validateSchema';
import {validateValueType} from './validateValueType';
import {warning} from './warning';

/**
 * validates data[part.name] according to "value" part
 * @param part to validate against
 * @param data to be validated, aka "local scope"
 * @param dataPath path from root object (global scope) to data
 * @param globalScope root data object to allow for evaluation of conditions
 * @param aggregateResult evaluation so far
 * @returns aggregateResult expanded with any validation results
 */
export const validateSchemaPart = (
  part: PartSchema<Valx>,
  data: Data,
  dataPath: string,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  codelistMap: CodeListMap | undefined,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  if (part.noValidation) {
    return aggregateResult;
  }

  const {value, valuePath} = getValue(data, part, dataPath);

  return validateValueType(
    part,
    validateSingleSchemaInstance,
    value,
    valuePath,
    undefined,
    globalScope,
    relatedScope,
    codelistMap,
    aggregateResult,
  );
};

const validateSingleSchemaInstance: ValidateSingleTypeValue<
  PartSchema<Valx>
> = (
  part: PartSchema<Valx>,
  value: DataValue | undefined,
  valuePath: string,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  codelistMap: CodeListMap | undefined,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  if (!part.required && isNullish(value)) {
    return aggregateResult;
  }

  if (part.required && !value) {
    return (part.required === true ? fail : warning)(
      {
        value,
        valuePath,
        part,
        localScope,
        validation: 'required',
        messageKey: 'required',
      },
      aggregateResult,
    );
  }

  return validateSchema(
    part,
    value,
    valuePath,
    globalScope,
    relatedScope,
    codelistMap,
    aggregateResult,
  );
};
