import {assert} from 'assert-ts';
import dayjs from 'dayjs';
import {Reference, RelativeDate, SchemaValue, Valx} from '../types';
import {ValueContext} from './types';
import {getPathKeys} from './getPathKeys';
import {isByNameRef, isGlobalRef, isPropRef, isRelatedRef} from './isRef';

export const resolveSchemaValue = (
  value: SchemaValue,
  context: ValueContext,
): SchemaValue<Valx> => {
  // Constant value
  if (
    typeof value === 'number' ||
    typeof value === 'string' ||
    typeof value === 'boolean' ||
    value === null
  ) {
    return {value, original: value};
  }

  assert.soft(
    typeof value === 'object',
    'resolveSchemaValue: object value expected, i.e. relative date or reference',
    value,
  );

  if ((value as RelativeDate).$date) {
    return resolveSchemaRelativeDate(value as RelativeDate);
  }

  return resolveSchemaReference(value as Reference, context);
};

const resolveSchemaRelativeDate = (
  value: RelativeDate | undefined,
): SchemaValue<Valx> => {
  // No reference
  if (value === undefined) {
    return {value: null, original: value};
  }

  const assertProps = {value};

  // Reference value
  assert(
    typeof value === 'object',
    'resolveSchemaRelativeDate: relative date value expected',
    assertProps,
  );

  const {$date} = value;
  assert($date, 'resolveSchemaRelativeDate: $date value expected', assertProps);

  const {offset, unit} = $date;
  const relativeValue = dayjs().add(offset, unit);
  return {value: relativeValue.format('YYYY-MM-DD'), original: value};
};

const resolveSchemaReference = (
  value: Reference | undefined,
  {scopes}: ValueContext,
): SchemaValue<Valx> => {
  // No reference
  if (value === undefined) {
    return {value: null, original: value};
  }

  const assertProps = {value, scopes};

  // Reference value
  assert(
    typeof value === 'object',
    'resolveSchemaReference: reference value expected',
    assertProps,
  );

  const {$ref} = value;
  assert($ref, 'evaluateRef: $ref value expected', assertProps);

  // Prop ref
  // - relative to current value
  if (isPropRef($ref)) {
    const keys = $ref.substring(1).split('.');
    return {propPathKeys: keys, original: value};
  }

  // By-name
  // - search for first key in $ref from contextPath and outwards
  if (isByNameRef($ref)) {
    const keys = getPathKeys($ref.substring(1));
    const firstKey = keys[0];

    // Local scope?
    if (scopes[scopes.length - 1].includes(firstKey as string)) {
      return {localPathKeys: keys, original: value};
    }

    return {globalByName: $ref, original: value};
  }

  // Global path
  if (isGlobalRef($ref)) {
    return {globalPathKeys: getPathKeys($ref.substring(1)), original: value};
  }

  // Related path
  assert(
    isRelatedRef($ref),
    'resolveSchemaValue: $ref must be prop, by-name, global or related, i.e. start with #, / or ^',
  );

  return {relatedPathKeys: getPathKeys($ref.substring(1)), original: value};
};
