import assert from 'assert-ts';
import {isEmptyValue, isNullish} from 'services/utils';
import {isMultiple} from 'schema/form/functions';
import {
  CodeListId,
  Data,
  DataValue,
  Part,
  PartLinkedAgent,
  PartSchema,
  VerifiedLinkedAgentMultiRole,
} from '../types';
import {PartValueVisitor} from './types';
import {getPartsFlattended} from './getPartsFlattended';
import {isLeafPart} from './isLeafPart';
import {resolveCompare} from './resolveCompare';

/**
 * Given a part with compare:
 * - 'subValues': recurse subValues until leaf value or value with part.compare: 'fullValue', excluding empty values.
 * - 'fullValue': visit full value
 * or leaf value: visit leaf value
 * @param part
 * @param value for part
 * @param valuePath path to value in schema context
 * @param visitor
 */
export const visitPartCompareSubvalues = (
  part: Part<unknown>,
  value: DataValue,
  valuePath: string,
  parentParts: Part<unknown>[] | undefined,
  visitor: PartValueVisitor<unknown>,
) => {
  if (isNullish(value) || isEmptyValue(value)) return;

  const compare = resolveCompare(part, parentParts);

  if (compare === 'fullValue') {
    visitor(part, valuePath);
    return;
  }

  if (compare === 'items') {
    assert(
      Array.isArray(value),
      'visitPartCompareSubvalues: "items" requires array value',
      {part, value},
    );
    assert(
      isMultiple(part.cardinality),
      'visitPartCompareSubvalues: "items" requires multiple cardinality',
      {part, value},
    );
    value.forEach((_, i) => {
      const subPath = `${valuePath}[${i}]`;
      visitor(part, subPath);
    });
    return;
  }

  // TODO: Handle expand, all branches
  if (part.type === 'expand') {
    assert.soft(false, 'visitPartCompareSubvalues: expand not implemented');
    return;
  }

  // Handle simple parts/scalar values (without subValues, except array of scalar values)
  if (isLeafPart(part)) {
    if (Array.isArray(value)) {
      value.forEach((_, i) => {
        const subPath = `${valuePath}[${i}]`;
        visitor(part, subPath);
      });
    } else {
      visitor(part, valuePath);
    }
    return;
  }

  // Recurse subValues
  switch (part.type) {
    case 'schema': {
      visitPartSchemaCompareSubvalues(
        part,
        value as Data | Data[],
        valuePath,
        parentParts,
        visitor,
      );
      return;
    }
    case 'linkedAgent': {
      visitLinkedAgentCompareSubvalues(
        part,
        value as Data | Data[],
        valuePath,
        parentParts,
        visitor,
      );
      return;
    }
    case 'linkedLiterary':
    case 'thesaurus': {
      assert(
        false,
        `visitPartCompareSubvalues: ${resolveCompare} not yet implemented for ${part.type}`,
      );
      return;
    }
  }
};

const visitPartSchemaCompareSubvalues = (
  part: PartSchema<unknown>,
  value: Data | Data[],
  valuePath: string,
  parentParts: Part<unknown>[] | undefined,
  visitor: PartValueVisitor<unknown>,
) => {
  const compare = resolveCompare(part, parentParts);
  assert(
    compare === 'subValues',
    'visitPartCompareSubvalues: schema must have compare: subValues',
  );

  if (
    !assert.soft(
      isMultiple(part.cardinality) === Array.isArray(value),
      'visitPartCompareSubvalues: value must match cardinality',
      {part, value},
    )
  ) {
    return;
  }

  const subparts = getPartsFlattended(part.parts);
  const subParentParts = [...(parentParts || []), part];

  if (Array.isArray(value)) {
    value.forEach((valueItem, i) => {
      if (isNullish(valueItem) || isEmptyValue(valueItem)) {
        return;
      }

      subparts.forEach(subPart => {
        const subValue = valueItem[subPart.name];
        const subPath = `${valuePath}[${i}].${subPart.name}`;
        visitPartCompareSubvalues(
          subPart,
          subValue,
          subPath,
          subParentParts,
          visitor,
        );
      });
    });
  } else {
    subparts.forEach(subPart => {
      const subPath = `${valuePath}.${subPart.name}`;
      const subValue = value[subPart.name];
      visitPartCompareSubvalues(
        subPart,
        subValue,
        subPath,
        subParentParts,
        visitor,
      );
    });
  }
};

const linkedAgentName: Part<unknown, keyof VerifiedLinkedAgentMultiRole> = {
  name: 'link',
  type: 'text',
};

const linkedRoles: Part<unknown, keyof VerifiedLinkedAgentMultiRole> = {
  name: 'roles',
  type: 'codelist',
  cardinality: 'multiple',
  codelistId: 'dummy' as CodeListId,
};

const visitLinkedAgentCompareSubvalues = (
  part: PartLinkedAgent<unknown>,
  value: Data | Data[],
  valuePath: string,
  parentParts: Part<unknown>[] | undefined,
  visitor: PartValueVisitor<unknown>,
) => {
  const compare = resolveCompare(part, parentParts);

  // fullValue has general handling outside this function
  assert(
    compare === 'subValues',
    'visitLinkedAgentCompareSubvalues: schema must have compare: subValues',
  );

  if (
    !assert.soft(
      isMultiple(part.cardinality) === Array.isArray(value),
      'visitPartCompareSubvalues: value must match cardinality',
      {part, value},
    )
  ) {
    return;
  }

  const subparts = part.entityRole
    ? [linkedAgentName]
    : [linkedAgentName, linkedRoles];

  if (Array.isArray(value)) {
    value.forEach((valueItem, i) => {
      if (isNullish(valueItem) || isEmptyValue(valueItem)) {
        return;
      }

      subparts.forEach(subPart => {
        const subPath = `${valuePath}[${i}].${subPart.name}`;
        visitor(subPart, subPath);
      });
    });
  } else {
    subparts.forEach(subPart => {
      const subPath = `${valuePath}.${subPart.name}`;
      visitor(subPart, subPath);
    });
  }
};
