export type EqualOptions = {
  falseEqualsNullish?: boolean;
  emptyStringEqualsNullish?: boolean;
};

const DEFAULT_OPTIONS: EqualOptions = {
  falseEqualsNullish: false,
  emptyStringEqualsNullish: false,
};

/**
 * Returns true if values are equal, considering empty arrays or objects
 * to equal null/undefined.
 *
 * @param a
 * @param b
 */
export const areValuesEqual = (
  a: unknown,
  b: unknown,
  options = DEFAULT_OPTIONS,
): boolean => {
  // Consider using schema to visit
  if (a === b) {
    return true;
  }

  if (isEmpty(a, options) && isEmpty(b, options)) {
    return true;
  }

  if (typeof a !== 'object' || typeof b !== 'object') {
    return false;
  }

  // Prevents "Cannot convert undefined or null to object"
  if (a === null || a === undefined || b === null || b === undefined) {
    return false;
  }

  const aKeys = Object.keys(a as object);
  const bKeys = Object.keys(b as object);
  const allKeys = [...aKeys, ...bKeys.filter(kb => !aKeys.includes(kb))];

  return allKeys.reduce<boolean>(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (acc, k) => acc && areValuesEqual(a ? a[k] : null, b ? b[k] : null),
    true,
  );
};

function isEmpty(obj: unknown, options: EqualOptions): boolean {
  if (obj == null || obj === undefined) {
    return true;
  }

  if (Array.isArray(obj)) {
    return obj.length === 0;
  }

  if (typeof obj === 'object') {
    return Object.keys(obj).length === 0;
  }

  if (options.emptyStringEqualsNullish && obj === '') {
    return true;
  }

  if (options.falseEqualsNullish && obj === false) {
    return true;
  }

  return false;
}
