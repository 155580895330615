import React, {useMemo} from 'react';
import {Stack} from '@mui/material';
import {Schema, Valx} from 'schemaDefinition/types';
import {useLocalization} from 'localization';
import {getGroupedParts} from 'schemaDefinition/functions';
import {FieldLayout, GroupLabel, Layout} from 'components';
import {
  SchemaGlobalScopeContextV2Provider,
  SchemaLocalScopeContextV2Provider,
} from 'schema/contexts';
import {BasePartProps} from './types';
import {useSchemaContainer} from '../hooks/useSchemaContainer';
import {DataFormSchemaFragment} from './DataFormSchemaFragment';

type DataFormSchemaProps = Omit<
  BasePartProps,
  'scopePath' | 'usesGlobalScope' | 'value'
> & {
  schema: Schema<Valx>;
};

/**
 * Sets local scope and renders form for given schema x value.
 * Use to render both global schema and part schemas on lower level.
 * @returns
 */
export const DataFormSchema: React.FC<DataFormSchemaProps> = ({
  schema,
  valuePath,
  relatedScope,
  mode,
  diff,
  noLabel = false,
  useValue,
  useOriginalValue,
}) => {
  const {tLoose} = useLocalization();

  const groupedParts = useMemo(
    () =>
      getGroupedParts(schema.parts, 'form').map(group => ({
        title: group.titleKey
          ? tLoose(`form.group.${group.titleKey}.title`)
          : undefined,
        parts: group.parts,
      })),
    [schema.parts, tLoose],
  );

  const Container = useSchemaContainer(schema);

  return (
    <SchemaGlobalScopeContextV2Provider
      schema={schema.globalScopePath ? schema : undefined}
      valuePath={schema.globalScopePath}>
      <SchemaLocalScopeContextV2Provider schema={schema} valuePath={valuePath}>
        <Stack spacing={2}>
          {groupedParts.map((group, idx) =>
            // TODO: Schema layout config
            schema.name === 'changeRequest' ? (
              <FieldLayout key={idx}>
                <DataFormSchemaFragment
                  useValue={useValue}
                  useOriginalValue={useOriginalValue}
                  valuePath={valuePath}
                  relatedScope={relatedScope}
                  fragment={group.parts}
                  mode={mode}
                  diff={diff}
                  noLabel={noLabel}
                />
              </FieldLayout>
            ) : (
              <Layout key={idx}>
                {group.title && <GroupLabel sx={{pl: 2}} label={group.title} />}
                <Container>
                  <FieldLayout>
                    <DataFormSchemaFragment
                      useValue={useValue}
                      useOriginalValue={useOriginalValue}
                      valuePath={valuePath}
                      relatedScope={relatedScope}
                      fragment={group.parts}
                      mode={mode}
                      diff={diff}
                      noLabel={noLabel}
                    />
                  </FieldLayout>
                </Container>
              </Layout>
            ),
          )}
        </Stack>
      </SchemaLocalScopeContextV2Provider>
    </SchemaGlobalScopeContextV2Provider>
  );
};
