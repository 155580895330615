import {assert} from 'assert-ts';
import {Data, DataValue, PartCodeList, Valx} from 'schemaDefinition/types';
import {CodeListMap} from 'api';
import {evaluateFieldRequired} from 'schemaDefinition/functions/evaluateFieldRequired';
import {ValidationResult} from './types';
import {fail} from './fail';
import {validResult} from './validResult';
import {validateConditions} from './validateConditions';
import {validateValueType} from './validateValueType';
import {warning} from './warning';

export const validateCodeList = (
  part: PartCodeList<Valx>,
  value: DataValue | undefined,
  valuePath: string,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  codelistMap: CodeListMap | undefined,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  return validateValueType(
    part,
    validateSingleCodeListValue,
    value,
    valuePath,
    localScope,
    globalScope,
    relatedScope,
    codelistMap,
    aggregateResult,
  );
};

const validateSingleCodeListValue = (
  part: PartCodeList<Valx>,
  value: DataValue | undefined,
  valuePath: string,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  codelistMap: CodeListMap | undefined,
  aggregateResult: ValidationResult,
): ValidationResult => {
  const codelist = codelistMap ? codelistMap[part.codelistId] : undefined;
  if (!codelist) {
    assert.soft(
      false,
      `Validate codelist part: Codelist ${part.codelistId} not found`,
    );

    return fail(
      {
        value,
        valuePath,
        part,
        localScope,
        validation: 'missing codelist',
        messageKey: 'missing.codelist',
      },
      aggregateResult,
    );
  }

  if (value === undefined || value === null) {
    const required = evaluateFieldRequired(
      part.required,
      valuePath,
      localScope,
      globalScope,
      relatedScope,
      value,
    );
    return required
      ? (required === true ? fail : warning)(
          {
            value,
            valuePath,
            part,
            localScope,
            validation: 'required',
            messageKey: 'required',
          },
          aggregateResult,
        )
      : aggregateResult;
  }

  if (typeof value !== 'string') {
    return fail(
      {
        value,
        valuePath,
        part,
        localScope,
        validation: 'invalid type',
        messageKey: 'invalid type',
      },
      aggregateResult,
    );
  }

  const code = codelist.codes.find(code => code.code === value);
  if (!code || code.disabled === true) {
    if (!code) {
      assert.soft(false, `Validate codelist part: Code not found: ${code}`, {
        codelistid: codelist.id,
        code,
      });
    }

    return fail(
      {
        value,
        valuePath,
        part,
        localScope,
        validation: 'invalid value',
        messageKey: 'invalid.code',
      },
      aggregateResult,
    );
  }

  if (part.validation) {
    return validateConditions(
      value,
      part.validation,
      valuePath,
      part,
      localScope,
      globalScope,
      relatedScope,
      aggregateResult,
    );
  }

  return aggregateResult;
};
