import {CodeList} from 'api/types';

export const getProductFormText = (
  productFormCode?: string,
  productFormCodelist?: CodeList,
  productFormOverrideCodelist?: CodeList,
): string | undefined => {
  if (!productFormCode) {
    return undefined;
  }

  /** Use override if exists. */
  if (productFormOverrideCodelist) {
    const override = productFormOverrideCodelist.codes.find(
      c => c.code === productFormCode,
    );
    if (override) {
      return override.value;
    }
  }

  /** Use normal codelist value if override is not present. */
  if (productFormCodelist) {
    const productForm = productFormCodelist.codes.find(
      c => c.code === productFormCode,
    );
    if (productForm) {
      return productForm.value;
    }
  }

  /** Fallback: Just use the code directly. */
  return productFormCode;
};
