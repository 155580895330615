import {
  AnyValidation,
  Data,
  DataValue,
  Part,
  Valx,
} from 'schemaDefinition/types';
import {evaluateCondition} from 'schemaDefinition/functions/evaluateCondition';
import {ValidationResult} from './types';
import {fail} from './fail';
import {validResult} from './validResult';
import {warning} from './warning';

export const validateConditions = <TPart extends Part<Valx>>(
  value: DataValue | undefined,
  conditions: AnyValidation<Valx> | AnyValidation<Valx>[],
  valuePath: string,
  part: TPart,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  (Array.isArray(conditions) ? conditions : [conditions]).forEach(condition => {
    if (
      !evaluateCondition(
        condition,
        valuePath,
        localScope,
        globalScope,
        relatedScope,
        value,
      )
    ) {
      aggregateResult = (condition.level === 'warning' ? warning : fail)(
        {
          value,
          valuePath,
          part,
          localScope,
          validation: condition,
          messageKey: condition.messageKey,
        },
        aggregateResult,
      );
    }
  });

  return aggregateResult;
};
