import {useMemo} from 'react';
import dayjs from 'dayjs';
import {Data, PartYear, Valx} from 'schemaDefinition/types';
import {getDateRange} from 'schemaDefinition/functions';

export const useYearRange = (
  part: PartYear<Valx>,
  valuePath: string,
  scope?: Data,
  globalScope?: Data,
  relatedScope?: Data,
): {
  minValue: number | undefined;
  maxValue: number | undefined;
} =>
  useMemo(() => {
    const dateRange = getDateRange(
      part.validation,
      valuePath,
      scope,
      globalScope,
      relatedScope,
    );
    return {
      minValue:
        dateRange.minValue === undefined
          ? undefined
          : dayjs(dateRange.minValue).year(),
      maxValue:
        dateRange.maxValue === undefined
          ? undefined
          : dayjs(dateRange.maxValue).year(),
    };
  }, [globalScope, part.validation, relatedScope, scope, valuePath]);
