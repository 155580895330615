import {useMemo} from 'react';
import {Part} from 'schemaDefinition/types';
import {useLocalization} from 'localization';
import {useSchemaGlobalScopeContextV2} from '../../contexts';
import {getFieldRootKeys} from '../functions';
import {getFieldString} from '../functions/getFieldString';

export const useDeleteAllStrings = <TVal>(
  part: Part<TVal>,
): {
  label?: string;
  confirm: {
    title?: string;
    okTitle?: string;
    cancelTitle?: string;
  };
} => {
  const localization = useLocalization();
  const {schema} = useSchemaGlobalScopeContextV2();
  return useMemo(() => {
    const rootKeys = getFieldRootKeys(
      schema.key,
      false,
      part.labelKey,
      part.name,
    );
    // const fie
    return {
      label:
        getFieldString(rootKeys, false, '.deleteAll', localization) ??
        localization.t('general.deleteAll'),
      confirm: {
        title: `${localization.t('general.deleteAll')}?`,
        okTitle: localization.t('general.delete'),
        cancelTitle: localization.t('general.cancel'),
      },
    };
  }, [localization, part.labelKey, part.name, schema.key]);
};
