import {assert} from 'assert-ts';
import {Data, DataValue, Part, Valx} from 'schemaDefinition/types';
import {ValidationResult} from './types';
import {evaluateFieldRequired} from '../../../../schemaDefinition';
import {isMultiple} from '../isMultiple';
import {fail} from './fail';
import {validResult} from './validResult';
import {warning} from './warning';

/**
 * Generic validation of multiple, i.e.:
 * - required: at least one item
 **/
export const validateMultipleValue = (
  part: Part<Valx>,
  value: DataValue | undefined,
  valuePath: string,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  assert(isMultiple(part.cardinality));

  if (value !== undefined && value !== null) {
    if (!Array.isArray(value)) {
      return fail(
        {
          value,
          valuePath,
          part,
          localScope,
          validation: 'invalid type',
          messageKey: 'invalidArrayValue',
        },
        aggregateResult,
      );
    }
  }

  const required = evaluateFieldRequired(
    part.required,
    valuePath,
    localScope,
    globalScope,
    relatedScope,
    value,
  );

  if (required) {
    if (!value || value.length === 0) {
      return (required === true ? fail : warning)(
        {
          value,
          valuePath,
          part,
          localScope,
          validation: 'required',
          messageKey: 'required',
        },
        aggregateResult,
      );
    }
  }

  return aggregateResult;
};
