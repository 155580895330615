import {
  AnyValidation,
  Condition,
  ValidationModifier,
} from 'schemaDefinition/types';
import {
  isAndCondition,
  isBooleanCondition,
  isComparisonCondition,
  isLengthCondition,
  isNotCondition,
  isOrCondition,
  isRangeCondition,
  isRegexCondition,
} from './isConditionType';

export const isAnyValidation = (
  validationModifier: ValidationModifier,
): validationModifier is AnyValidation => {
  const condition = validationModifier as Condition;
  return (
    isBooleanCondition(condition) ||
    isRangeCondition(condition) ||
    isLengthCondition(condition) ||
    isRegexCondition(condition) ||
    isComparisonCondition(condition) ||
    isNotCondition(condition) ||
    isOrCondition(condition) ||
    isAndCondition(condition)
  );
};
