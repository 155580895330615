import {Concept} from 'types';
import {CodeListRef, ExpressionV4} from 'api/types';
import {
  AnyValidation,
  Condition,
  Separator,
  TypedSchema,
  TypedSchemaModifierMap,
} from 'schemaDefinition/types';
import {
  MetadataModifierKey,
  StatusModifierKey,
  WorkBook,
  WorkMerchandise,
} from '../types';
import {
  validateExternalLinkedAgentId,
  validateLinkedAgentId,
} from './partValidations';

/**
 * Expression schema
 */

const expressionContentPart: TypedSchema<ExpressionV4>['parts'][0] = {
  type: 'codelist',
  name: 'content',
  cardinality: 'multiple',
  codelistId: 'EXPRESSION_CONTENT',
  labelKey: 'content',
};

const expressionValidation_AudioRequiresReader: AnyValidation = {
  // AUDIO => 'Lest av' role must be set, i.e.: (!AUDIO or 'Lest av)
  messageKey: 'AudioRequiresReaderRole',
  level: 'warning',
  op: 'or',
  arg: [
    {
      op: 'not',
      arg: {
        arg: {$ref: '/expressionFormat'},
        regex: CodeListRef.EXPRESSION_FORMAT.AUDIO,
        default: false,
      },
    },
    {
      // Will match against JSON of agents w/roles, use quotes to match against string in roles array
      regex: `"${CodeListRef.EXPRESSION_ROLE_TYPE['Lest av']}"`,
      default: false,
    },
  ],
};

const hasLanguage: Condition = {arg: {$ref: '/languages[0]'}};
const hasWorkLanguage: Condition = {arg: {$ref: '^work.languages[0]'}};

const expressionValidation_DiffLanguageRequiresTranslator: AnyValidation = {
  // work languages != expression language => 'Oversatt av' role must be set, i.e.: (same language (or null) or 'Oversatt av')
  messageKey: 'DiffLanguageRequiresTranslatorRole',
  level: 'warning',
  op: 'or',
  arg: [
    {
      op: 'not',
      arg: hasLanguage,
    },
    {
      op: 'not',
      arg: hasWorkLanguage,
    },
    {
      op: 'eq',
      arg1: {$ref: '/languages[0]'},
      arg2: {$ref: '^work.languages[0]'},
    },
    {
      // Will match against JSON of agents w/roles, use quotes to match against string in roles array
      regex: `"${CodeListRef.EXPRESSION_ROLE_TYPE['Oversatt av']}"`,
      default: false,
    },
  ],
};

export const expressionDataSchema: TypedSchema<ExpressionV4> = {
  name: Concept.expression,
  parts: [
    {
      type: 'linkedAgent',
      name: 'agents',
      cardinality: 'multiple',
      roleCodelistId: 'EXPRESSION_ROLE_TYPE',
      entitySubtypes: [Concept.person, Concept.corporation, Concept.event],
      compare: 'subValues',
    },
    Separator('card', 'none'),
    {
      type: 'codelist',
      name: 'languages',
      cardinality: 'multiple',
      codelistId: 'LANGUAGE',
      labelKey: 'language',
    },
    {
      type: 'codelist',
      name: 'intermediateOriginalLanguage',
      codelistId: 'LANGUAGE',
    },
    {
      type: 'codelist',
      name: 'expressionFormat',
      codelistId: 'EXPRESSION_FORMAT',
      required: true,
    },
    {
      type: 'expand',
      role: 'expressionContentAndSpecialChars',
      when: [
        {
          condition: {
            op: 'eq',
            arg1: {$ref: '#expressionFormat'},
            arg2: CodeListRef.EXPRESSION_FORMAT.TEXT,
          },
          parts: [[expressionContentPart]],
        },
        {
          condition: {
            op: 'eq',
            arg1: {$ref: '#expressionFormat'},
            arg2: CodeListRef.EXPRESSION_FORMAT.ILLUSTRATED_TEXT,
          },
          parts: [[expressionContentPart]],
        },
      ],
    },
  ],
};

export const expressionSchemaModifiers: TypedSchemaModifierMap<
  ExpressionV4,
  MetadataModifierKey
> = {
  [`${WorkMerchandise}.${StatusModifierKey.precat}`]: [
    {
      name: 'languages',
      required: true,
    },
  ],
  [`${WorkMerchandise}.${StatusModifierKey.cat}`]: [
    {
      name: 'agents',
      listValidation: [
        expressionValidation_DiffLanguageRequiresTranslator,
        expressionValidation_AudioRequiresReader,
      ],
      validation: [validateLinkedAgentId, validateExternalLinkedAgentId],
    },
    {
      name: 'languages',
      required: true,
    },
  ],
  [`${WorkBook}.${StatusModifierKey.precat}`]: [
    {
      name: 'languages',
      required: true,
    },
  ],
  [`${WorkBook}.${StatusModifierKey.cat}`]: [
    {
      name: 'agents',
      listValidation: [
        expressionValidation_DiffLanguageRequiresTranslator,
        expressionValidation_AudioRequiresReader,
      ],
      validation: [validateLinkedAgentId, validateExternalLinkedAgentId],
    },
    {
      name: 'languages',
      required: true,
    },
  ],
};
