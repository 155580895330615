import {assert} from 'assert-ts';
import {CodeListMap} from 'api/types';
import {
  Data,
  DataValue,
  LinkedAgentMultiRole,
  PartLinkedAgent,
  UnnamedLinkedAgentMultiRole,
  UnverifiedLinkedAgentMultiRole,
  Valx,
  VerifiedLinkedAgentMultiRole,
} from 'schemaDefinition/types';
import {
  evaluateFieldRequired,
  getPartCodelistFromLinkedAgent,
} from 'schemaDefinition/functions';
import {getPartCodelistFromUnnamedAgentRole} from 'schemaDefinition/functions/getPartCodelistFromUnnamedAgentRole';
import {isLinkedValue} from 'schemaDefinition/functions/isLinkedValue';
import {ValidationResult} from './types';
import {fail} from './fail';
import {validResult} from './validResult';
import {validateCodeList} from './validateCodeList';
import {validateConditions} from './validateConditions';
import {validateValueType} from './validateValueType';
import {warning} from './warning';

export const validateLinkedAgent = (
  part: PartLinkedAgent<Valx>,
  value: DataValue | undefined,
  valuePath: string,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  codelistMap: CodeListMap | undefined,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  return validateValueType(
    part,
    validateSingleLinkedAgentValue,
    value,
    valuePath,
    localScope,
    globalScope,
    relatedScope,
    codelistMap,
    aggregateResult,
  );
};

const validateSingleLinkedAgentValue = (
  part: PartLinkedAgent<Valx>,
  value: DataValue | undefined,
  valuePath: string,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  codelistMap: CodeListMap | undefined,
  aggregateResult: ValidationResult,
): ValidationResult => {
  if (value === undefined || value === null) {
    const required = evaluateFieldRequired(
      part.required,
      valuePath,
      localScope,
      globalScope,
      relatedScope,
      value,
    );
    return required
      ? (required === true ? fail : warning)(
          {
            value,
            valuePath,
            part,
            localScope,
            validation: 'required',
            messageKey: 'required',
          },
          aggregateResult,
        )
      : aggregateResult;
  }

  if (!isLinkedValue(value)) {
    return fail(
      {
        value,
        valuePath,
        part,
        localScope,
        validation: 'invalid type',
        messageKey: 'invalid type',
      },
      aggregateResult,
    );
  }

  const linkAggregateResult = validateSingleLinkedAgentLink(
    part,
    value,
    valuePath,
    localScope,
    globalScope,
    relatedScope,
    aggregateResult,
  );
  const rolesAggregateResult = validateSingleLinkedAgentRoles(
    part,
    value,
    valuePath,
    localScope,
    globalScope,
    relatedScope,
    codelistMap,
    linkAggregateResult,
  );

  return (value as UnnamedLinkedAgentMultiRole).link.linkStatus === 'unnamed'
    ? validateSingleUnnamedAgentRole(
        part,
        value,
        valuePath,
        localScope,
        globalScope,
        relatedScope,
        codelistMap,
        rolesAggregateResult,
      )
    : rolesAggregateResult;
};

export const validateSingleLinkedAgentLink = (
  part: PartLinkedAgent<Valx>,
  value: DataValue | undefined,
  valuePath: string,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  const verified = value as VerifiedLinkedAgentMultiRole;
  const unverified = value as UnverifiedLinkedAgentMultiRole;

  const required = evaluateFieldRequired(
    part.required,
    valuePath,
    localScope,
    globalScope,
    relatedScope,
    value,
  );

  if (required && !(verified?.link?.agentName || unverified?.link?.name)) {
    return (required === true ? fail : warning)(
      {
        value,
        valuePath,
        part,
        localScope,
        validation: 'required',
        messageKey: 'required',
      },
      aggregateResult,
    );
  }

  if (part.validation) {
    return validateConditions(
      value,
      part.validation,
      valuePath,
      part,
      localScope,
      globalScope,
      relatedScope,
      aggregateResult,
    );
  }

  return aggregateResult;
};

export const validateSingleLinkedAgentRoles = (
  part: PartLinkedAgent<Valx>,
  value: DataValue | undefined,
  valuePath: string,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  codelistMap: CodeListMap | undefined,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  if (part.entityRole) return aggregateResult;

  const rolesPart = assert(
    getPartCodelistFromLinkedAgent(part),
    'validateSingleLinkedValueRoles: codelistId expected',
    part,
  );
  const rolesValue = value ? (value as LinkedAgentMultiRole).roles : undefined;
  const rolesPath = valuePath + '.roles';
  return validateCodeList(
    rolesPart,
    rolesValue,
    rolesPath,
    localScope,
    globalScope,
    relatedScope,
    codelistMap,
    aggregateResult,
  );
};

export const validateSingleUnnamedAgentRole = (
  part: PartLinkedAgent<Valx>,
  value: DataValue | undefined,
  valuePath: string,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  codelistMap: CodeListMap | undefined,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  if (part.entityRole) return aggregateResult;

  const rolePart = getPartCodelistFromUnnamedAgentRole();

  const roleValue = value
    ? (value as UnnamedLinkedAgentMultiRole).unnamedAgentRole
    : undefined;
  const rolePath = valuePath + '.unnamedAgentRole';
  return validateCodeList(
    rolePart,
    roleValue,
    rolePath,
    localScope,
    globalScope,
    relatedScope,
    codelistMap,
    aggregateResult,
  );
};
