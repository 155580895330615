import {
  AndCondition,
  BooleanCondition,
  ComparisonCondition,
  Condition,
  LengthCondition,
  NotCondition,
  OrCondition,
  RangeCondition,
  RegexCondition,
} from '../types';

const baseConditionKeys = ['id', 'level', 'messageKey'];

export const isRangeCondition = <TVal>(
  condition: Condition<TVal>,
): condition is RangeCondition<TVal> => {
  const range = condition as RangeCondition<TVal>;
  return range.min !== undefined || range.max !== undefined;
};

export const isLengthCondition = <TVal>(
  condition: Condition<TVal>,
): condition is LengthCondition<TVal> => {
  const length = condition as LengthCondition<TVal>;
  return length.minLength !== undefined || length.maxLength !== undefined;
};

export const isRegexCondition = <TVal>(
  condition: Condition<TVal>,
): condition is RegexCondition<TVal> => {
  const regex = condition as RegexCondition<TVal>;
  return regex.regex !== undefined;
};

export const isComparisonCondition = <TVal>(
  condition: Condition<TVal>,
): condition is ComparisonCondition<TVal> => {
  const comparison = condition as ComparisonCondition<TVal>;
  return (
    ['lt', 'le', 'eq', 'ge', 'gt'].includes(comparison.op) && !!comparison.arg2
  );
};

export const isNotCondition = <TVal>(
  condition: Condition<TVal>,
): condition is NotCondition<TVal> => {
  const not = condition as NotCondition<TVal>;
  return not.op === 'not' && !!not.arg;
};

export const isOrCondition = <TVal>(
  condition: Condition<TVal>,
): condition is OrCondition<TVal> => {
  const or = condition as OrCondition<TVal>;
  return or.op === 'or' && Array.isArray(or.arg);
};

export const isAndCondition = <TVal>(
  condition: Condition<TVal>,
): condition is AndCondition<TVal> => {
  const and = condition as AndCondition<TVal>;
  return and.op === 'and' && Array.isArray(and.arg);
};

const boolConditionKeys = ['arg'];

export const isBooleanCondition = <TVal>(
  condition: Condition<TVal>,
): condition is BooleanCondition<TVal> => {
  const asBoolean = condition as BooleanCondition<TVal>;
  return (
    typeof asBoolean === 'object' &&
    typeof asBoolean.arg === 'object' &&
    Object.keys(asBoolean).every(
      key => boolConditionKeys.includes(key) || baseConditionKeys.includes(key),
    )
  );
};
