import {ManifestationV4} from 'api/types';
import {useLocalization} from 'localization';
import {useCodelist} from '../../../../../services/codeLists';
import {getManifestationHeaderTitle} from '../functions';

export const useManifestationTitle = (
  manifestation?: ManifestationV4,
): string => {
  const {t} = useLocalization();

  const productFormOverrideCodelist = useCodelist('product_form_simple_values');

  const productFormCodelist = useCodelist('PRODUCT_FORM');

  return (
    getManifestationHeaderTitle(
      manifestation,
      productFormCodelist,
      productFormOverrideCodelist,
    ) || t('page.metadata.manifestation.default.title')
  );
};
