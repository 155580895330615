import {CodeListMap} from 'api/types';
import {Data, DataValue, PartNameVariant, Valx} from 'schemaDefinition/types';
import {isNameVariantValue} from 'schemaDefinition/functions/isNameVariantValue';
import {ValidationResult} from './types';
import {evaluateFieldRequired} from '../../../../schemaDefinition';
import {fail} from './fail';
import {getCustomValidator} from './getCustomValidator';
import {validResult} from './validResult';
import {validateValueType} from './validateValueType';
import {warning} from './warning';

export const validateNameVariant = (
  part: PartNameVariant<Valx>,
  value: DataValue | undefined,
  valuePath: string,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  return validateValueType(
    part,
    validateSingleNameVariantValue,
    value,
    valuePath,
    localScope,
    globalScope,
    relatedScope,
    undefined,
    aggregateResult,
  );
};

const validateSingleNameVariantValue = (
  part: PartNameVariant<Valx>,
  value: DataValue | undefined,
  valuePath: string,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  _codelistMap: CodeListMap | undefined,
  aggregateResult: ValidationResult,
): ValidationResult => {
  if (value === undefined || value === null) {
    const required = evaluateFieldRequired(
      part.required,
      valuePath,
      localScope,
      globalScope,
      relatedScope,
      value,
    );
    return required
      ? (required === true ? fail : warning)(
          {
            value,
            valuePath,
            part,
            localScope,
            validation: 'required',
            messageKey: 'required',
          },
          aggregateResult,
        )
      : aggregateResult;
  }

  if (!isNameVariantValue(value)) {
    return fail(
      {
        value,
        valuePath,
        part,
        localScope,
        validation: 'invalid type',
        messageKey: 'invalid type',
      },
      aggregateResult,
    );
  }

  const validator = getCustomValidator(part);

  const valid = validator ? validator(part, value) : 'valid';

  return valid === 'valid'
    ? aggregateResult
    : (valid === 'error' ? fail : warning)(
        {
          value,
          valuePath,
          part,
          localScope,
          validation: 'invalid value',
          messageKey: 'invalidNameVariant',
        },
        aggregateResult,
      );
};
