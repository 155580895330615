import {ManifestationV4} from 'api/types';
import {FormatIcon, Layout, Spacer, Text} from 'components';
import {useManifestationTitle} from './ManifestationCard/hooks/useManifestationTitle';

type Props = {
  manifestation?: ManifestationV4;
  fontWeight?: 'bold' | 'normal';
};

export const ManifestationTitle: React.FC<Props> = ({
  manifestation,
  fontWeight = 'bold',
}) => {
  const title = useManifestationTitle(manifestation);

  return (
    <Layout horizontal adjustCenter adjustLeft>
      <FormatIcon productForm={manifestation?.productForm} />
      <Spacer size={0.5} />
      <Text variant="h4" sx={{fontWeight}}>
        {title}
      </Text>
    </Layout>
  );
};
