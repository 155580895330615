import {CodeListMap} from 'api/types';
import {Data, DataValue, PartThesaurus, Valx} from 'schemaDefinition/types';
import {ValidationResult} from './types';
import {evaluateFieldRequired} from '../../../../schemaDefinition';
import {fail} from './fail';
import {validResult} from './validResult';
import {validateConditions} from './validateConditions';
import {validateValueType} from './validateValueType';
import {warning} from './warning';

export const validateThesaurus = (
  part: PartThesaurus<Valx>,
  value: DataValue | undefined,
  valuePath: string,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  const baseValidationResult = validateValueType(
    part,
    validateSingleThesaurusValue,
    value,
    valuePath,
    localScope,
    globalScope,
    relatedScope,
    undefined,
    aggregateResult,
  );

  return part.validation
    ? validateConditions(
        value,
        part.validation,
        valuePath,
        part,
        localScope,
        globalScope,
        relatedScope,
        baseValidationResult,
      )
    : baseValidationResult;
};

const validateSingleThesaurusValue = (
  part: PartThesaurus<Valx>,
  value: DataValue | undefined,
  valuePath: string,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  _codelistMap: CodeListMap | undefined,
  aggregateResult: ValidationResult,
): ValidationResult => {
  if (value === undefined || value === null) {
    const required = evaluateFieldRequired(
      part.required,
      valuePath,
      localScope,
      globalScope,
      relatedScope,
      value,
    );
    return required
      ? (required === true ? fail : warning)(
          {
            value,
            valuePath,
            part,
            localScope,
            validation: 'required',
            messageKey: 'required',
          },
          aggregateResult,
        )
      : aggregateResult;
  }

  if (typeof value !== 'string') {
    return fail(
      {
        value,
        valuePath,
        part,
        localScope,
        validation: 'invalid type',
        messageKey: 'invalid type',
      },
      aggregateResult,
    );
  }

  return aggregateResult;
};
