import {createContext, useContext, useMemo} from 'react';
import {assert} from 'assert-ts';
import {Data, Schema, Valx} from 'schemaDefinition/types';

export type SchemaScopeContextV1Type = {
  schema: Schema<Valx>;
  values: Data;
};

export const SchemaGlobalScopeContextV1 = createContext<
  SchemaScopeContextV1Type | undefined
>(undefined);

export const useSchemaGlobalScopeContextV1ProviderValue = (
  schema: Schema<Valx>,
  values: Data,
): SchemaScopeContextV1Type =>
  useMemo(
    () => ({
      schema,
      values,
    }),
    [schema, values],
  );

export const useSchemaGlobalScopeContextV1 = (): SchemaScopeContextV1Type => {
  return assert(
    useContext(SchemaGlobalScopeContextV1),
    'useSchemaGlobalScopeContextV1: context expected',
  );
};

export const SchemaLocalScopeContextV1 = createContext<
  SchemaScopeContextV1Type | undefined
>(undefined);

export const useSchemaLocalScopeContextV1 = (): SchemaScopeContextV1Type => {
  return assert(
    useContext(SchemaLocalScopeContextV1),
    'useSchemaLocalScopeContextV1: context expected',
  );
};
