import {CodeListMap} from 'api/types';
import {
  Data,
  DataValue,
  PartCodeListOrText,
  PartText,
  PartTextArea,
  Valx,
} from 'schemaDefinition/types';
import {ValidationResult} from './types';
import {evaluateFieldRequired} from '../../../../schemaDefinition';
import {fail} from './fail';
import {validResult} from './validResult';
import {validateConditions} from './validateConditions';
import {validateValueType} from './validateValueType';
import {warning} from './warning';

export const validateText = (
  part: PartText<Valx> | PartTextArea<Valx>,
  value: DataValue | undefined,
  valuePath: string,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  return validateValueType(
    part,
    validateSingleTextValue,
    value,
    valuePath,
    localScope,
    globalScope,
    relatedScope,
    undefined,
    aggregateResult,
  );
};

export const validateSingleTextValue = (
  part: PartText<Valx> | PartTextArea<Valx> | PartCodeListOrText<Valx>,
  value: DataValue | undefined,
  valuePath: string,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  _codelistMap: CodeListMap | undefined,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  if (!value && part.required) {
    const required = evaluateFieldRequired(
      part.required,
      valuePath,
      localScope,
      globalScope,
      relatedScope,
      value,
    );
    return (required === true ? fail : warning)(
      {
        value,
        valuePath,
        part,
        localScope,
        validation: 'required',
        messageKey: 'required',
      },
      aggregateResult,
    );
  }

  if (value !== undefined && value !== null && typeof value !== 'string') {
    return fail(
      {
        value,
        valuePath,
        part,
        localScope,
        validation: 'invalid type',
        messageKey: 'invalid type',
      },
      aggregateResult,
    );
  }

  if (part.validation) {
    return validateConditions(
      value,
      part.validation,
      valuePath,
      part,
      localScope,
      globalScope,
      relatedScope,
      aggregateResult,
    );
  }

  return aggregateResult;
};
