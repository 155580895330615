import {CodeListMap} from 'api/types';
import {
  Data,
  DataValue,
  Part,
  PartExpand,
  Schema,
  SeparatedParts,
  Valx,
} from 'schemaDefinition/types';
import {evaluateCondition, isData} from 'schemaDefinition/functions';
import {ValidationResult} from './types';
import {fail} from './fail';
import {validResult} from './validResult';
import {validateSchemaPart} from './validateSchemaPart';
import {validateValuePart} from './validateValuePart';

/**
 * validates data according to schema
 * @param schema to validate against
 * @param data to be validated, aka "local scope"
 * @param dataPath path from root object (global scope) to data. Empty string for root schema
 * @param globalScope root data object to allow for evaluation of conditions
 * @param aggregateResult evaluation so far
 * @returns aggregateResult expanded with any validation results
 */
export const validateSchema = (
  schema: Schema<Valx>,
  data: DataValue | undefined,
  dataPath: string,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  codelistMap: CodeListMap | undefined,
  aggregateResult: ValidationResult,
): ValidationResult => {
  if (!isData(data)) {
    return fail(
      {
        value: data,
        valuePath: dataPath,
        part: schema,
        localScope: globalScope,
        validation: 'invalid type',
        messageKey: 'invalidType',
      },
      aggregateResult,
    );
  }

  return validateParts(
    schema.parts,
    data as Data,
    dataPath,
    globalScope,
    relatedScope,
    codelistMap,
    aggregateResult,
  );
};

/**
 * validates data according to given parts, i.e. from a schema or expand case/default
 * @param parts to validate against
 * @param data to be validated, aka "local scope"
 * @param dataPath path from root object (global scope) to data
 * @param globalScope root data object to allow for evaluation of conditions
 * @param aggregateResult evaluation so far
 * @returns aggregateResult expanded with any validation results
 */
const validateParts = (
  parts: SeparatedParts<Valx>,
  data: Data,
  dataPath: string,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  codelistMap: CodeListMap | undefined,
  aggregateResult: ValidationResult,
): ValidationResult => {
  return parts.reduce(
    (acc, part) =>
      Array.isArray(part)
        ? validateParts(
            part,
            data,
            dataPath,
            globalScope,
            relatedScope,
            codelistMap,
            acc,
          )
        : part.type === 'separator'
        ? acc
        : validatePart(
            part,
            data,
            dataPath,
            globalScope,
            relatedScope,
            codelistMap,
            acc,
          ),
    aggregateResult,
  );
};

/**
 * validates data according part, i.e.:
 * - expand part: validate data according to any active case
 * - schema part: validate data[part.name] according to subschema
 * - "value" parts: validate data[part.name] according to part
 * @param part to validate against
 * @param data to be validated, aka "local scope"
 * @param dataPath path from root object (global scope) to data
 * @param globalScope root data object to allow for evaluation of conditions
 * @param aggregateResult evaluation so far
 * @returns aggregateResult expanded with any validation results
 */
export const validatePart = (
  part: Part<Valx>,
  data: Data,
  dataPath: string,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  codelistMap: CodeListMap | undefined,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  switch (part.type) {
    // case 'schema':
    // Not tested
    // {
    //   const {value, valuePath} = getValue(data, part, dataPath);
    //   return validateSchema(
    //     part,
    //     value,
    //     valuePath,
    //     globalScope,
    //     aggregateResult,
    //   );
    // }
    case 'schema': {
      return validateSchemaPart(
        part,
        data,
        dataPath,
        globalScope,
        relatedScope,
        codelistMap,
        aggregateResult,
      );
    }
    case 'expand': {
      return validateExpandPart(
        part,
        data,
        dataPath,
        globalScope,
        relatedScope,
        codelistMap,
        aggregateResult,
      );
    }
    default: {
      return validateValuePart(
        part,
        data,
        dataPath,
        globalScope,
        relatedScope,
        codelistMap,
        aggregateResult,
      );
    }
  }
};

/**
 * validates data to any active case
 * @param part expand part to validate against
 * @param data to be validated, aka "local scope"
 * @param dataPath path from root object (global scope) to data
 * @param globalScope root data object to allow for evaluation of conditions
 * @param aggregateResult evaluation so far
 * @returns aggregateResult expanded with any validation results
 */
const validateExpandPart = (
  part: PartExpand<Valx>,
  data: Data,
  dataPath: string,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  codelistMap: CodeListMap | undefined,
  aggregateResult: ValidationResult,
): ValidationResult => {
  // Determine active case, if any
  const activeCase = part.when.find(w =>
    evaluateCondition(
      w.condition,
      dataPath,
      data,
      globalScope,
      relatedScope,
      undefined,
    ),
  );

  const activeParts = activeCase ? activeCase.parts : part.default;
  return activeParts
    ? validateParts(
        activeParts,
        data,
        dataPath,
        globalScope,
        relatedScope,
        codelistMap,
        aggregateResult,
      )
    : aggregateResult;
};
