import {assert} from 'assert-ts';
import {Condition, Data, DataValue, RefPath, Valx} from '../types';
import {evaluateAndCondition} from './evaluateAndCondition';
import {evaluateBooleanCondition} from './evaluateBooleanCondition';
import {evaluateExpressionCondition} from './evaluateExpressionCondition';
import {evaluateLengthCondition} from './evaluateLengthCondition';
import {evaluateNotCondition} from './evaluateNotCondition';
import {evaluateOrCondition} from './evaluateOrCondition';
import {evaluateRangeCondition} from './evaluateRangeCondition';
import {evaluateRegexCondition} from './evaluateRegexCondition';
import {
  isAndCondition,
  isBooleanCondition,
  isComparisonCondition,
  isLengthCondition,
  isNotCondition,
  isOrCondition,
  isRangeCondition,
  isRegexCondition,
} from './isConditionType';

export const evaluateCondition = (
  condition: Condition<Valx> | Condition<Valx>[],
  /**
   * Path from global scope to evaluation context
   */
  contextPath: RefPath,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  localValue: DataValue | undefined,
): boolean => {
  // Array?
  if (Array.isArray(condition)) {
    return condition.reduce<boolean>(
      (acc, c) =>
        acc &&
        evaluateCondition(
          c,
          contextPath,
          localScope,
          globalScope,
          relatedScope,
          localValue,
        ),
      true,
    );
  }

  if (isRangeCondition(condition)) {
    return evaluateRangeCondition(
      condition,
      contextPath,
      localScope,
      globalScope,
      relatedScope,
      localValue,
    );
  }

  if (isLengthCondition(condition)) {
    return evaluateLengthCondition(
      condition,
      contextPath,
      localScope,
      globalScope,
      relatedScope,
      localValue,
    );
  }

  if (isRegexCondition(condition)) {
    return evaluateRegexCondition(
      condition,
      contextPath,
      localScope,
      globalScope,
      relatedScope,
      localValue,
    );
  }

  if (isComparisonCondition(condition)) {
    return evaluateExpressionCondition(
      condition,
      contextPath,
      localScope,
      globalScope,
      relatedScope,
      localValue,
    );
  }

  if (isNotCondition(condition)) {
    return evaluateNotCondition(
      condition,
      contextPath,
      localScope,
      globalScope,
      relatedScope,
      localValue,
    );
  }

  if (isOrCondition(condition)) {
    return evaluateOrCondition(
      condition,
      contextPath,
      localScope,
      globalScope,
      relatedScope,
      localValue,
    );
  }

  if (isAndCondition(condition)) {
    return evaluateAndCondition(
      condition,
      contextPath,
      localScope,
      globalScope,
      relatedScope,
      localValue,
    );
  }
  if (isBooleanCondition(condition)) {
    return evaluateBooleanCondition(
      condition,
      contextPath,
      localScope,
      globalScope,
      relatedScope,
      localValue,
    );
  }

  assert.soft(false, 'evaluateCondition: unknown condition', {condition});

  return false;
};
