import {Data, DataValue, RangeCondition, RefPath, Valx} from '../types';
import {compareDateValues} from './compareDateValues';
import {evaluateValxWithDefault} from './evaluateValxWithDefault';
import {getDateRange} from './getDateRange';
import {getDateMaxValue, getDateMinValue} from './getDateValue';

export const evaluateDateRangeCondition = (
  condition: RangeCondition<Valx>,
  /**
   * Path from global scope to evaluation context
   */
  contextPath: RefPath,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  localValue: DataValue | undefined,
): boolean => {
  const {minValue, maxValue} = getDateRange(
    condition,
    contextPath,
    localScope,
    globalScope,
    relatedScope,
  );

  const arg1Valx = evaluateValxWithDefault(
    condition.arg1,
    contextPath,
    localScope,
    globalScope,
    relatedScope,
    localValue,
  );

  // In case of year, arg1 is date range 1-1 - 12-31,
  // and arg1Low <= max and arg1High >= min
  const arg1LowValue = getDateMinValue(arg1Valx);
  const arg1HighValue = getDateMaxValue(arg1Valx);

  return (
    (arg1HighValue === undefined ||
      minValue === undefined ||
      compareDateValues(arg1HighValue, minValue) !== -1) &&
    (arg1LowValue === undefined ||
      maxValue === undefined ||
      compareDateValues(arg1LowValue, maxValue) !== 1)
  );
};
