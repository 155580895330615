import {
  Part,
  PartExpand,
  PartSchema,
  Schema,
  SchemaValueType,
  SeparatedParts,
} from '../types';
import {PartVisitor} from './types';

export type ShouldVisitSubSchema<TVal> = (
  part: PartSchema<TVal>,
  parents: Part<TVal>[] | undefined,
) => boolean;

/**
 * Visits all parts in schema and any subschemas.
 * Visitor will be called both for nested schema-parts and each of the
 * parts in the nested schemas
 * @param mutableSchema, schema is mutable and may be adjusted by visitor
 * @param visitor
 * @param path, default empty, but set when called recursively for sub-schemas
 * @param mutableParentParts, if provided, parent parts will be pushed, passed to visitor, and popped
 */
export const visitSchemaParts = <TVal = SchemaValueType>(
  mutableSchema: Schema<TVal>,
  visitor: PartVisitor<TVal>,
  shouldVisitSubSchema: ShouldVisitSubSchema<TVal> = () => true,
  path = '',
  mutableParentParts: Part<TVal>[] | undefined = undefined,
) => {
  visitParts(
    mutableSchema.parts,
    visitor,
    shouldVisitSubSchema,
    path,
    mutableParentParts,
  );
};

const visitPart = <TVal = SchemaValueType>(
  part: Part<TVal>,
  visitor: PartVisitor<TVal>,
  shouldVisitSubSchema: ShouldVisitSubSchema<TVal>,
  path: string,
  mutableParentParts: Part<TVal>[] | undefined,
) => {
  const partPath = path ? `${path}.${part.name}` : part.name ?? '';

  switch (part.type) {
    case 'expand': {
      visitExpandParts(
        part,
        visitor,
        shouldVisitSubSchema,
        path,
        mutableParentParts,
      );
      return;
    }
    case 'schema': {
      visitor(part, partPath, undefined, mutableParentParts);
      if (shouldVisitSubSchema(part, mutableParentParts)) {
        mutableParentParts?.push(part);
        visitSchemaParts(
          part,
          visitor,
          shouldVisitSubSchema,
          partPath,
          mutableParentParts,
        );
        mutableParentParts?.pop();
      }
      return;
    }
    default: {
      visitor(part, partPath, undefined, mutableParentParts);
      return;
    }
  }
};

const visitParts = <TVal = SchemaValueType>(
  parts: SeparatedParts<TVal> = [],
  visitor: PartVisitor<TVal>,
  shouldVisitSubSchema: ShouldVisitSubSchema<TVal>,
  path: string,
  mutableParentParts: Part<TVal>[] | undefined,
) => {
  parts.forEach(part => {
    if (Array.isArray(part)) {
      visitParts(part, visitor, shouldVisitSubSchema, path, mutableParentParts);
    } else if (part.type !== 'separator') {
      visitPart(part, visitor, shouldVisitSubSchema, path, mutableParentParts);
    }
  });
};

export const visitExpandParts = <TVal = SchemaValueType>(
  expand: PartExpand<TVal>,
  visitor: PartVisitor<TVal>,
  shouldVisitSubSchema: ShouldVisitSubSchema<TVal>,
  path: string,
  mutableParentParts: Part<TVal>[] | undefined,
) => {
  expand.when.forEach(w => {
    visitParts(
      w.parts,
      (part, partPath) => visitor(part, partPath, 'expand.when'),
      shouldVisitSubSchema,
      path,
      mutableParentParts,
    );
  });

  visitParts(
    expand.default,
    (part, partPath) => visitor(part, partPath, 'expand.default'),
    shouldVisitSubSchema,
    path,
    mutableParentParts,
  );
};
