/**
 * Check if the product form is merchandise
 *
 * https://bokbasen.jira.com/wiki/spaces/EBBA/pages/2896494601/Felter+fysisk+beskrivelse+manifestasjon+etter+produktform+Onixliste+150
 */
export const isMerchProductForm = (code: string) => {
  const merchProductFormCodes = [
    'CE',
    'PZ',
    'PF', // Dagbok
    'PJ', // Postkort
    'PA',
    'PC',
    'PK',
    'PR',
    'PM', // Mappe
    /*
    'ZY',
    'ZI',
    'ZG',
    'ZD',
    'ZJ',
    'ZE',
    'ZZ',
    'ZC',
    'ZM',
    'ZN',
    'ZL',
    'ZA',
    'XE',
    'XM',
     */
  ];

  return (
    merchProductFormCodes.includes(code) || code[0] === 'X' || code[0] === 'Z'
  );
};
