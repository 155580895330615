import {Data, DataValue, RefPath, Valx} from '../types';
import {evaluateValx} from './evaluateValx';

/**
 * If value cannot be converted to argType, then it is undefined.
 */
export const evaluateValxWithDefault = (
  valx: Valx | undefined,
  /**
   * Path from global scope to evaluation context, or local scope property
   */
  contextPath: RefPath,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  defaultValue: DataValue | undefined,
): DataValue | undefined => {
  return valx !== undefined
    ? evaluateValx(valx, contextPath, localScope, globalScope, relatedScope)
    : defaultValue ?? null;
};
