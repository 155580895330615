import {assert} from 'assert-ts';
import {CodeListMap} from 'api/types';
import {ExpressionValidationResult} from 'schemas/types';
import {getRestrictedExpressionCodelists} from 'schemas/codelistRestrictors';
import {
  ExpressionIds,
  Metadata,
  MetadataSchemas,
  MetadataStatuses,
} from '../types';
import {aggregateExpressionValid} from './aggregateExpressionValid';
import {getExpressionLocalCodelistMap} from './getExpressionLocalCodelistMap';
import {validateEntity} from './validateEntity';
import {validateManifestation} from './validateManifestation';

/**
 * Validate expression and its manifestations
 */
export const validateExpression = (
  expressionIds: ExpressionIds,
  savedMetadata: Pick<Metadata, 'work' | 'expressions' | 'manifestations'>,
  metadata: Pick<Metadata, 'work' | 'expressions' | 'manifestations'>,
  schemas: MetadataSchemas,
  codelists: CodeListMap,
  statuses: MetadataStatuses,
): ExpressionValidationResult => {
  const {work} = metadata;

  const savedExpression = assert(
    savedMetadata.expressions.find(e => e.id === expressionIds.expressionId),
    'validateExpression: saved expression not found',
  );

  const expression = assert(
    metadata.expressions.find(e => e.id === expressionIds.expressionId),
    'validateExpression: expression not found',
  );

  const localCodelistMap = getExpressionLocalCodelistMap(
    expressionIds.expressionId,
    {metadataEdit: {metadata}},
    // Can use PRODUCT_FORM here since it is only for validation, not presentation
    assert(codelists.PRODUCT_FORM, 'validateExpression: no PRODUCT_FORM'),
    assert(codelists.PRODUCT_FORM, 'validateExpression: no PRODUCT_FORM'),
  );

  const combinedCodelists = {
    ...codelists,
    ...localCodelistMap,
  };

  // Restrict codelists for expression
  const expressionCodelists = getRestrictedExpressionCodelists(
    {work, expression},
    combinedCodelists,
  );

  const {validation: expressionValidation, status: expressionStatus} =
    validateEntity(
      savedExpression,
      expression,
      {work},
      work.type,
      statuses[expressionIds.expressionId],
      schemas.expression.schema,
      schemas.expression.getDataBasedModifier,
      schemas.expression.modifiers,
      expressionCodelists,
    );

  // Validate manifestations
  const manifestationValidations = expressionIds.manifestationIds.map(
    manifestationId => {
      const {validation: manifestationValidation, status: manifestationStatus} =
        validateManifestation(
          manifestationId,
          {work, expression},
          savedMetadata,
          metadata,
          schemas,
          combinedCodelists,
          statuses,
        );
      return {
        manifestationId,
        manifestationStatus,
        validation: manifestationValidation,
      };
    },
  );

  const result: ExpressionValidationResult = {
    valid: aggregateExpressionValid(
      expressionValidation,
      manifestationValidations.map(m => m.validation),
    ),
    expressionId: expression.id,
    expressionStatus,
    validation: expressionValidation,
    manifestations: manifestationValidations,
  };

  return result;
};
