import {CodeListMap} from 'api/types';
import {Data, DataValue, PartDate, Valx} from 'schemaDefinition/types';
import {ValidationResult} from './types';
import {evaluateFieldRequired} from '../../../../schemaDefinition';
import {fail} from './fail';
import {validResult} from './validResult';
import {validateValueType} from './validateValueType';
import {validateYearOrDateValidations} from './validateYearOrDateValidations';
import {warning} from './warning';

export const validateDate = (
  part: PartDate<Valx>,
  value: DataValue | undefined,
  valuePath: string,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  return validateValueType(
    part,
    validateSingleDateValue,
    value,
    valuePath,
    localScope,
    globalScope,
    relatedScope,
    undefined,
    aggregateResult,
  );
};

export const validateSingleDateValue = (
  part: PartDate<Valx>,
  value: DataValue | undefined,
  valuePath: string,
  localScope: Data | undefined,
  globalScope: Data | undefined,
  relatedScope: Data | undefined,
  _codelistMap: CodeListMap | undefined,
  aggregateResult: ValidationResult = validResult(),
): ValidationResult => {
  if (value === undefined || value === null) {
    const required = evaluateFieldRequired(
      part.required,
      valuePath,
      localScope,
      globalScope,
      relatedScope,
      value,
    );
    return required
      ? (required === true ? fail : warning)(
          {
            value,
            valuePath,
            part,
            localScope,
            validation: 'required',
            messageKey: 'required',
          },
          aggregateResult,
        )
      : aggregateResult;
  }

  if (!isValidDate(value)) {
    return fail(
      {
        value,
        valuePath,
        part,
        localScope,
        validation: 'invalid type',
        messageKey: 'invalidDate',
      },
      aggregateResult,
    );
  }

  if (part.validation) {
    return validateYearOrDateValidations(
      value,
      part.validation,
      valuePath,
      part,
      localScope,
      globalScope,
      relatedScope,
      aggregateResult,
    );
  }

  return aggregateResult;
};

export const isValidDate = (value: unknown): boolean =>
  typeof value === 'string' && /^-?\d{4}-\d{2}-\d{2}$/.test(value);
